<template>
  <div class="container-main">
    <div class="masthead-wrapper">
      <div
        class="container masthead masthead-tablet"
        style="display: flex; padding: 0px"
      >
        <div class="logo" style="float: left"></div>
        <div
          class="logo_title-tablet"
          style="float: left; font-size: 20px; width: 220px"
        >
          Order Tracking
        </div>
        <div class="menu-tablet" style="float: right; width: 296px">
          <span class="btnMenuItem" style="margin-left: 0px; margin-right: 0px"
            >0800 426 473</span
          >
          <span class="btnMenuItem" style="margin-left: 0px; margin-right: 0px"
            ><a href="/faq" style="text-decoration: none; color: white"
              >FAQs</a
            ></span
          >
          <div
            class="login"
            id="btnLogin"
            style="
              margin-top: -5px;
              margin-left: 10px;
              font-size: 12px;
              width: auto;
            "
            v-on:click="btnLoginClick"
            v-if="ifGuid == false"
          >
            <div style="">Log In</div>
          </div>
          <div
            class="login"
            id="btnLogout"
            style="
              margin-top: -5px;
              margin-left: 10px;
              display: none;
              font-size: 12px;
              width: auto;
            "
            v-on:click="btnLogoutClick"
            v-if="ifGuid == false"
          >
            <div
              style="
                padding: 5px 10px;
                border-radius: 20px;
                border: white 1px solid;
              "
            >
              Log Out
            </div>
          </div>
        </div>

        <div class="emailAddr" style="margin-left: 60px; display: none">
          {{ session.email }}
        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <h3 style="width: 740px" v-if="orders.length > 0" class="header1-tablet">
        Track your order
        <span v-if="orders.length > 0">{{ orders[0].so_order_no }}</span>
        <button
          v-if="customer.guid_url != null && ifGuid == false"
          v-bind:link="customer.guid_url"
          v-on:click="btnShareClick"
          class="btnOfficeMax btnShare"
          style="margin-top: 12px"
        >
          <a>Share with customer</a>
        </button>
      </h3>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <div
        v-if="orders.length > 0 && alertInfo != null"
        class="alertArea-Tablet"
        style="
          width: 740px;
          margin-left: 0px;
          height: 62px;
          align-items: center;
          justify-content: center;
        "
      >
        <img src="../../public/images/Subtract.svg" />
        <p style="padding: 10px; margin-top: -30px; margin-left: 20px">
          {{ alertInfo }}
        </p>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <div style="margin-left: 0px auto; width: 740px">
        <div
          v-if="session.token.length > 1"
          style="width: 740px"
          class="info_area-tablet"
        >
          <h4>Search by OfficeMax Order Number</h4>
          <h5>eg:12345678</h5>
          <div id="searchbar">
            <div
              id="search_input"
              style="display: flex"
              :class="{ 'active-border': inputFocused }"
            >
              <div style="float: left">
                <input
                  type="text"
                  id="terms"
                  autocomplete="off"
                  name="Ntt"
                  class="text_box"
                  value=""
                  @keyup.enter="btnSearchClick"
                  @focus="inputFocused = true"
                  @blur="inputFocused = false"
                  maxlength="8"
                  oninput="this.value = this.value.replace(/\D/g, '')"
                  v-model="orderNoBind"
                />
              </div>
              <div style="float: left">
                <button
                  class="new_search"
                  value="Search"
                  id="btnSearch"
                  v-on:click="btnSearchClick"
                >
                  <img
                    src="../../public/images/search.svg"
                    alt="Search"
                    style="
                      margin-left: 10px;
                      margin-right: 5px;
                      width: 13px;
                      height: 13px;
                    "
                  />Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style="display: none; width: 740px" id="search-alert">
          <div class="img"></div>
          <p></p>
        </div>
        <div id="right_panel" style="display: none; margin-top: 33px">
          <div style="display: flex">
            <div
              style="
                width: 360px;
                height: 330px;
                float: left;
                margin-right: 20px;
                padding: 15px;
              "
              class="info_area"
            >
              <div class="block">
                <div class="line-low" v-if="customer.customer_number != null">
                  <div class="bold" style="color: #657e91; width: 155px">
                    Customer Number
                  </div>
                  <div style="width: 185px" id="custNoCustName">
                    {{ customer.customer_number }}
                  </div>
                </div>
                <div class="line-low" v-if="customer.customer_name != null">
                  <div class="bold" style="color: #657e91; width: 155px">
                    Customer Name
                  </div>
                  <div style="width: 185px" id="custNoCustName">
                    {{ customer.customer_name }}
                  </div>
                </div>
                <div
                  class="line-low"
                  v-if="customer.officemax_order_number != null"
                >
                  <div class="bold" style="color: #657e91; width: 155px">
                    OfficeMax Order Number
                  </div>
                  <div style="width: 185px" id="orderedBy">
                    {{ customer.officemax_order_number }}
                  </div>
                </div>
                <div
                  class="line-low"
                  v-if="customer.customer_order_number != null"
                >
                  <div class="bold" style="color: #657e91; width: 155px">
                    Customer Order Number
                  </div>
                  <div style="width: 185px" id="orderedBy">
                    {{ customer.customer_order_number }}
                  </div>
                </div>
                <div class="line-low" v-if="customer.order_by != null">
                  <div class="bold" style="color: #657e91; width: 155px">
                    Ordered By
                  </div>
                  <div style="width: 185px" id="orderedBy">
                    {{ customer.order_by }}
                  </div>
                </div>
                <div class="line-low" v-if="customer.order_date != null">
                  <div class="bold" style="color: #657e91; width: 155px">
                    Ordered On
                  </div>
                  <div style="width: 185px" id="orderedOn">
                    {{ formatDateToOct(customer.order_date) }}
                  </div>
                </div>
                <div class="line-low" v-if="customer.order_phone != null">
                  <div class="bold" style="color: #657e91; width: 155px">
                    Orderer Phone
                  </div>
                  <div style="width: 185px" id="customerReference">
                    {{ customer.order_phone }}
                  </div>
                </div>
                <div class="line-low" v-if="customer.order_email != null">
                  <div class="bold" style="color: #657e91; width: 155px">
                    Orderer Email
                  </div>
                  <div
                    style="width: 185px; overflow-wrap: break-word"
                    id="customerReference"
                  >
                    {{ customer.order_email }}
                  </div>
                </div>
                <div class="line-low" v-if="customer.payment_method != null">
                  <div class="bold" style="color: #657e91; width: 155px">
                    Payment Method
                  </div>
                  <div
                    style="width: 185px; overflow-wrap: break-word"
                    id="customerReference"
                  >
                    {{ customer.payment_method }}
                  </div>
                </div>
              </div>
            </div>
            <div
              style="width: 360px; height: 330px; float: left; padding: 15px"
              class="info_area"
            >
              <div class="block">
                <div class="line-low bold">
                  <div style="color: #657e91">Delivery Address:</div>
                </div>
                <div class="line-low address">
                  <div
                    id="shippingAddress"
                    v-html="customer.shipping_address"
                  ></div>
                </div>
              </div>
              <div class="block">
                <div class="line-low bold">
                  <div style="color: #657e91">
                    Special Delivery Instruction:
                  </div>
                </div>
                <div class="line-low address">
                  <div
                    id="specialIns"
                    v-html="customer.special_delivery_instruction"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="width: 740px; display: flex; margin-top: 10px"
            class="ship_info"
            v-if="shipInfo != null"
          >
            <p
              class="packageInfo"
              style="display: block; align-self: baseline; padding: 0px"
            >
              {{ shipInfo }}
            </p>
          </div>
          <p
            v-if="orders.length > 0"
            class="packageInfo"
            style="width: 900px; height: 50px; display: block; margin-top: 10px"
          >
            <img
              src="../../public/images/SubtractBlue.svg"
              style="margin-right: 10px"
            />{{ orders.length }} part(s) in OfficeMax Order Number
            {{ orders[0].so_order_no }}
          </p>
        </div>

        <div
          class="orderArea"
          v-for="(order, index) in orders"
          v-bind:key="order.id"
          :class="`order${index + 1}`"
          style="margin-bottom: 33px"
        >
          <div
            v-if="order.sales_order_type == 0"
            style="width: 740px; height: 370px; position: relative"
            class="info_area"
          >
            <div
              style="
                width: 740px;
                height: 30px;
                position: relative;
                margin-bottom: 20px;
              "
            >
              <div
                class="orderTitle"
                style="
                  width: 190px;
                  height: 26px;
                  margin-top: -33px;
                  margin-left: -35px;
                  background-color: #94aaba !important;
                "
              >
                {{ order.so_warehouse }}
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 10px !important;
                font-weight: normal;
                display: flex;
              "
            >
              <div class="bold">
                OfficeMax Order Number: {{ order.so_order_no }}
              </div>
              <span class="splitter" style="margin-right: 20px">|</span>
              <div v-if="order.so_order_suffix != null">
                <span class="bold">Part: </span>{{ order.so_order_suffix }}
              </div>
              <span class="splitter" style="margin-right: 20px">|</span>
              <div>
                <span class="bold" v-if="order.packages_in_batch != null"
                  >Boxes in this part: </span
                >{{ order.packages_in_batch }}
              </div>
              <div
                v-if="order.full_tracking_url != null"
                :class="getStyleObject(order.is_all_delivered)"
                style="
                  text-align: center;
                  margin-left: 20px !important;
                  margin-top: -10px !important;
                  width: auto !important;
                  height: auto !important;
                "
              >
                <a
                  style="font-size: 11px !important"
                  v-bind:href="order.full_tracking_url"
                  target="_blank"
                  >Check Courier Status</a
                >
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 11px !important;
                font-weight: normal;
                display: flex;
              "
            >
              <div style="width: 250px"><span class="bold">&nbsp;</span></div>
              <div style="width: 239px"></div>
              <div>
                <span class="bold">Carrier: </span>{{ order.so_carrier }}
              </div>
            </div>
            <div
              class="line-low"
              style="margin-top: 20px; width: 900px; position: relative"
            >
              <div class="withOfficeMax">With OfficeMax</div>
              <div class="withBlank">&nbsp;</div>
              <div class="withBlank" style="width: 232px !important">
                &nbsp;
              </div>
              <div class="withCarrier">With Carrier</div>
              <!-- <div class="withBlank">&nbsp;</div> -->
              <div
                class="brownAreaOverlay"
                style="z-index: 1; width: 171px !important"
              ></div>
            </div>
            <div class="line-low" style="margin-top: 20px; position: relative">
              <div class="tooltip tooltip-tablet-low" style="display: none">
                <p class="tooltipContent">xxxxxxxxxxxxxxxx</p>
              </div>
              <div
                class="tooltipBoxNumber tooltipBoxNumber-tablet-low"
                style="display: none"
              >
                <p class="tooltipContent">
                  This indicates the number of boxes at this stage.
                </p>
              </div>
              <div
                style="display: flex; width: 740px; margin-left: 25px"
                class="imgDivs"
              >
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withOfficeMax">With OfficeMax</div>                                                     -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[0]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status1" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[0] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[0] }}&nbsp;</div>
                </div>
                <div class="imageLine" style="width: 92px !important">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withBlank" >&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[1]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status2" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[1] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[1] }}&nbsp;</div>
                </div>
                <div class="imageLine" style="width: 92px !important">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div>                     -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[2]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status3" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[2] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[2] }}&nbsp;</div>
                </div>
                <div class="imageLine" style="width: 92px !important">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:90px;" class="withCarrier">With Carrier</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[3]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status4" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[3] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[3] }}&nbsp;</div>
                </div>
                <div
                  class="imageLine brownOverlay"
                  style="width: 92px !important"
                >
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[4]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status5" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[4] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[4] }}&nbsp;</div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: left;
                  width: 740px;
                  height: 40px;
                  /* margin-top: 130px; */
                  /* margin-left: -800px; */
                "
              >
                <div
                  v-if="
                    order.status[2] == 1 &&
                    order.is_send_delivery_paperwork == true
                  "
                  class="btnOfficeMax btnOrderEmpty"
                  style="float: left; margin-right: 50px"
                  v-bind:data-orderNo="order.so_order_no"
                  v-bind:data-prefix="order.so_order_suffix"
                  v-on:click="btnOrderEmptyClick"
                >
                  <a href="#">Send Delivery Paperwork</a>
                </div>
                <div
                  v-if="order.products_detail != null"
                  class="btnOfficeMax btnOrder btnProductDetails"
                  style="float: left; margin-right: 50px; opacity: 1"
                >
                  <a href="#">View By Product</a>
                </div>
                <div
                  v-if="order.box_details != null"
                  class="btnOfficeMax btnOrder btnBoxDetails"
                  style="float: left; opacity: 1"
                >
                  <a href="#">View Box Summary</a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="boxDetailList"
            v-if="
              order.box_details != null &&
              order.box_details.length > 0 &&
              order.sales_order_type == 0
            "
            style="display: none; width: 740px; padding-top: 5px"
          >
            <p>Box Total : {{ order.box_details.length }}</p>
            <div
              v-for="box in order.box_details"
              v-bind:key="box.package_id"
              style="width: 670px"
            >
              <div
                style="
                  width: 670px;
                  display: flex;
                  align-items: center;
                  margin-top: 35px;
                "
              >
                <p
                  class="boxStatus"
                  style="
                    display: block;
                    float: left;
                    margin-right: 180px;
                    width: 400px;
                  "
                >
                  Box Status : <span>{{ box.status }}</span>
                </p>
                <div
                  v-if="box.tracking_url != null"
                  :class="getStyleObject(box.is_delivered)"
                  style="margin-left: 0px !important"
                >
                  <a v-bind:href="box.tracking_url" target="_blank"
                    >Check Courier Status</a
                  >
                </div>
              </div>

              <div style="display: table; width: 670px">
                <div class="box_headers">
                  <div
                    class="box_header"
                    v-for="(header, index) in box.headers"
                    v-bind:key="'header' + index"
                    :style="boxHearderWidth[index]"
                  >
                    <span>{{ header }}</span>
                  </div>
                </div>
                <div
                  class="box_line"
                  v-for="(line, index) in box.lines"
                  v-bind:key="'line' + index"
                >
                  <div class="line_item">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="productDetailList"
            v-if="order.products_detail != null && order.sales_order_type == 0"
            style="display: none; width: 740px; padding-top: 5px"
          >
            <p>{{ order.products_detail.description }}</p>
            <div style="display: table; width: 670px">
              <div class="box_headers">
                <div
                  class="box_header"
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidth[index]"
                >
                  <span>{{ header }}</span>
                </div>
              </div>
              <div
                class="box_line"
                v-for="(line, index) in order.products_detail.lines"
                v-bind:key="'line' + index"
              >
                <div class="line_item">
                  {{ line.product_no }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_description)"
                >
                  {{ line.product_description }}
                </div>
                <div class="line_item">
                  {{ line.uom }}
                </div>
                <div class="line_item">
                  {{ line.qty_ordered }}
                </div>
                <div class="line_item">
                  {{ line.qty_shipped }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_delayed)"
                >
                  {{ line.qty_delayed }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="order.sales_order_type == 1"
            style="width: 740px; height: 370px; position: relative"
            class="info_area"
          >
            <div
              style="
                width: 740px;
                height: 30px;
                position: relative;
                margin-bottom: 20px;
              "
            >
              <div
                class="orderTitle"
                style="
                  width: 190px;
                  height: 26px;
                  margin-top: -33px;
                  margin-left: -35px;
                "
              >
                Direct Ship
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 10px !important;
                font-weight: normal;
                display: flex;
              "
            >
              <div class="bold">
                OfficeMax Order Number: {{ order.so_order_no }}
              </div>
              <span class="splitter" style="margin-right: 20px">|</span>
              <div v-if="order.so_order_suffix != null">
                <span class="bold">Part: </span>{{ order.so_order_suffix }}
              </div>
              <span class="splitter" style="margin-right: 20px">|</span>
              <div><span class="bold">&nbsp;</span></div>
              <div
                v-if="order.full_tracking_url != null"
                :class="getStyleObject(order.is_all_delivered)"
                style="
                  text-align: center;
                  margin-left: 20px !important;
                  margin-top: -10px !important;
                  width: auto !important;
                  height: auto !important;
                "
              >
                <a
                  style="font-size: 11px !important"
                  v-bind:href="order.full_tracking_url"
                  target="_blank"
                  >Check Courier Status</a
                >
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 11px !important;
                font-weight: normal;
                display: flex;
              "
            >
              <div style="width: 250px"><span class="bold">&nbsp;</span></div>
              <div style="width: 235px"></div>
              <div style="display: none">
                <span class="bold">Carrier: </span>{{ order.so_carrier }}
              </div>
            </div>
            <div
              class="line-low"
              style="margin-top: 20px; width: 900px; position: relative"
            >
              <div
                class="withSupplier"
                style="background-color: #007fc7; opacity: 1"
              >
                With Supplier
              </div>
              <div class="withBlank">&nbsp;</div>
              <div class="withBlank" style="width: 232px !important">
                &nbsp;
              </div>
              <div class="withCarrier">With Carrier</div>
              <!-- <div class="withBlank">&nbsp;</div> -->
              <div
                class="brownAreaOverlay"
                style="z-index: 1; width: 171px !important"
              ></div>
            </div>
            <div class="line-low" style="margin-top: 20px; position: relative">
              <div class="tooltip tooltip-tablet-low" style="display: none">
                <p class="tooltipContent">xxxxxxxxxxxxxxxx</p>
              </div>
              <div
                class="tooltipBoxNumber tooltipBoxNumber-tablet-low"
                style="display: none"
              >
                <p class="tooltipContent">
                  This indicates the number of boxes at this stage.
                </p>
              </div>
              <div
                style="display: flex; width: 740px; margin-left: 25px"
                class="imgDivs"
              >
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withOfficeMax">With OfficeMax</div>                                                     -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[0]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status1" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[0] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[0] }}&nbsp;</div>
                </div>
                <div class="imageLine" style="width: 92px !important">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withBlank" >&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[1]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status2" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[1] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[1] }}&nbsp;</div>
                </div>
                <div class="imageLine" style="width: 92px !important">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[2]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status3" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[2] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[2] }}&nbsp;</div>
                </div>
                <div
                  class="imageLine brownOverlay"
                  style="width: 92px !important"
                >
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[3]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status4" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[3] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[3] }}&nbsp;</div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: left;
                  width: 740px;
                  height: 40px;
                  /* margin-top: 130px;
                  margin-left: -619px; */
                "
              >
                <div
                  v-if="
                    order.status[2] == 1 &&
                    order.is_send_delivery_paperwork == true
                  "
                  class="btnOfficeMax btnOrderEmpty"
                  style="float: left; margin-right: 50px"
                  v-bind:data-orderNo="order.so_order_no"
                  v-bind:data-prefix="order.so_order_suffix"
                  v-on:click="btnOrderEmptyClick"
                >
                  <a href="#">Send Delivery Paperwork</a>
                </div>
                <div
                  v-if="order.products_detail != null"
                  class="btnOfficeMax btnOrder btnProductDetails"
                  style="float: left; margin-right: 50px; opacity: 1"
                >
                  <a href="#">View By Product</a>
                </div>
                <div
                  v-if="order.box_details != null"
                  class="btnOfficeMax btnOrder btnBoxDetails"
                  style="float: left; opacity: 1"
                >
                  <a href="#">View Box Summary</a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="boxDetailList"
            v-if="
              order.box_details != null &&
              order.box_details.length > 0 &&
              order.sales_order_type == 1
            "
            style="display: none; width: 740px; padding-top: 5px"
          >
            <p>Box Total : {{ order.box_details.length }}</p>
            <div
              v-for="box in order.box_details"
              v-bind:key="box.package_id"
              style="width: 670px"
            >
              <div
                style="
                  width: 670px;
                  display: flex;
                  align-items: center;
                  margin-top: 35px;
                "
              >
                <p
                  class="boxStatus"
                  style="
                    display: block;
                    float: left;
                    margin-right: 180px;
                    width: 400px;
                  "
                >
                  Box Status : <span>{{ box.status }}</span>
                </p>
                <div
                  v-if="box.tracking_url != null"
                  :class="getStyleObject(box.is_delivered)"
                  style="margin-left: 0px !important"
                >
                  <a v-bind:href="box.tracking_url" target="_blank"
                    >Check Courier Status</a
                  >
                </div>
              </div>

              <div style="display: table; width: 670px">
                <div class="box_headers">
                  <div
                    class="box_header"
                    v-for="(header, index) in box.headers"
                    v-bind:key="'header' + index"
                    :style="boxHearderWidth[index]"
                  >
                    <span>{{ header }}</span>
                  </div>
                </div>
                <div
                  class="box_line"
                  v-for="(line, index) in box.lines"
                  v-bind:key="'line' + index"
                >
                  <div class="line_item">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="productDetailList"
            v-if="order.products_detail != null && order.sales_order_type == 1"
            style="display: none; width: 740px; padding-top: 5px"
          >
            <p>{{ order.products_detail.description }}</p>
            <div style="display: table; width: 670px">
              <div class="box_headers">
                <div
                  class="box_header"
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidth[index]"
                >
                  <span>{{ header }}</span>
                </div>
              </div>
              <div
                class="box_line"
                v-for="(line, index) in order.products_detail.lines"
                v-bind:key="'line' + index"
              >
                <div class="line_item">
                  {{ line.product_no }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_description)"
                >
                  {{ line.product_description }}
                </div>
                <div class="line_item">
                  {{ line.uom }}
                </div>
                <div class="line_item">
                  {{ line.qty_ordered }}
                </div>
                <div class="line_item">
                  {{ line.qty_shipped }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_delayed)"
                >
                  {{ line.qty_delayed }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="order.sales_order_type == 2"
            style="width: 740px; height: 370px; position: relative"
            class="info_area"
          >
            <div
              style="
                width: 740px;
                height: 30px;
                position: relative;
                margin-bottom: 20px;
              "
            >
              <div
                class="orderTitle"
                style="
                  width: 190px;
                  height: 26px;
                  margin-top: -33px;
                  margin-left: -35px;
                  background-color: #ed1c24;
                "
              >
                Dangerous Goods
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 10px !important;
                font-weight: normal;
                display: flex;
              "
            >
              <div class="bold">
                OfficeMax Order Number: {{ order.so_order_no }}
              </div>
              <span class="splitter" style="margin-right: 20px">|</span>
              <div v-if="order.so_order_suffix != null">
                <span class="bold">Part: </span>{{ order.so_order_suffix }}
              </div>
              <span class="splitter" style="margin-right: 20px">|</span>
              <div>
                <span class="bold" v-if="order.packages_in_batch != null"
                  >Boxes in this part: </span
                >{{ order.packages_in_batch }}
              </div>
              <div
                v-if="order.full_tracking_url != null"
                :class="getStyleObject(order.is_all_delivered)"
                style="
                  text-align: center;
                  margin-left: 20px !important;
                  margin-top: -10px !important;
                  width: auto !important;
                  height: auto !important;
                "
              >
                <a
                  style="font-size: 11px !important"
                  v-bind:href="order.full_tracking_url"
                  target="_blank"
                  >Check Courier Status</a
                >
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 11px !important;
                font-weight: normal;
                display: flex;
              "
            >
              <div style="width: 280px">
                <span class="bold">Warehouse: </span>{{ order.so_warehouse }}
              </div>
              <div style="width: 209px"></div>
              <div>
                <span class="bold">Carrier: </span>{{ order.so_carrier }}
              </div>
            </div>
            <div
              class="line-low"
              style="margin-top: 20px; width: 900px; position: relative"
            >
              <div class="withOfficeMax">With OfficeMax</div>
              <div class="withBlank">&nbsp;</div>
              <div class="withBlank" style="width: 232px !important">
                &nbsp;
              </div>
              <div class="withCarrier">With Carrier</div>
              <!-- <div class="withBlank">&nbsp;</div> -->
              <div
                class="brownAreaOverlay"
                style="width: 171px !important"
              ></div>
            </div>
            <div class="line-low" style="margin-top: 20px; position: relative">
              <div class="tooltip tooltip-tablet-low" style="display: none">
                <p class="tooltipContent">xxxxxxxxxxxxxxxx</p>
              </div>
              <div
                class="tooltipBoxNumber tooltipBoxNumber-tablet-low"
                style="display: none"
              >
                <p class="tooltipContent">
                  This indicates the number of boxes at this stage.
                </p>
              </div>
              <div
                style="display: flex; width: 740px; margin-left: 25px"
                class="imgDivs"
              >
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withOfficeMax">With OfficeMax</div>                                                     -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[0]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status1" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[0] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[0] }}&nbsp;</div>
                </div>
                <div class="imageLine" style="width: 92px !important">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withBlank" >&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[1]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status2" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[1] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[1] }}&nbsp;</div>
                </div>
                <div class="imageLine" style="width: 92px !important">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div>                     -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[2]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status3" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[2] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[2] }}&nbsp;</div>
                </div>
                <div class="imageLine" style="width: 92px !important">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:90px;" class="withCarrier">With Carrier</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[3]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status4" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[3] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[3] }}&nbsp;</div>
                </div>
                <div
                  class="imageLine brownOverlay"
                  style="width: 92px !important"
                >
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[4]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status5" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[4] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[4] }}&nbsp;</div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: left;
                  width: 740px;
                  height: 40px;
                  /* margin-top: 130px;
                  margin-left: -800px; */
                "
              >
                <div
                  v-if="
                    order.status[2] == 1 &&
                    order.is_send_delivery_paperwork == true
                  "
                  class="btnOfficeMax btnOrderEmpty"
                  style="float: left; margin-right: 50px"
                  v-bind:data-orderNo="order.so_order_no"
                  v-bind:data-prefix="order.so_order_suffix"
                  v-on:click="btnOrderEmptyClick"
                >
                  <a href="#">Send Delivery Paperwork</a>
                </div>
                <div
                  v-if="order.products_detail != null"
                  class="btnOfficeMax btnOrder btnProductDetails"
                  style="float: left; margin-right: 50px; opacity: 1"
                >
                  <a href="#">View By Product</a>
                </div>
                <div
                  v-if="order.box_details != null"
                  class="btnOfficeMax btnOrder btnBoxDetails"
                  style="float: left; opacity: 1"
                >
                  <a href="#">View Box Summary</a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="boxDetailList"
            v-if="
              order.box_details != null &&
              order.box_details.length > 0 &&
              order.sales_order_type == 2
            "
            style="display: none; width: 740px; padding-top: 5px"
          >
            <p>Box Total : {{ order.box_details.length }}</p>
            <div
              v-for="box in order.box_details"
              v-bind:key="box.package_id"
              style="width: 670px"
            >
              <div
                style="
                  width: 670px;
                  display: flex;
                  align-items: center;
                  margin-top: 35px;
                "
              >
                <p
                  class="boxStatus"
                  style="
                    display: block;
                    float: left;
                    margin-right: 180px;
                    width: 400px;
                  "
                >
                  Box Status : <span>{{ box.status }}</span>
                </p>
                <div
                  v-if="box.tracking_url != null"
                  :class="getStyleObject(box.is_delivered)"
                  style="margin-left: 0px !important"
                >
                  <a v-bind:href="box.tracking_url" target="_blank"
                    >Check Courier Status</a
                  >
                </div>
              </div>

              <div style="display: table; width: 670px">
                <div class="box_headers">
                  <div
                    class="box_header"
                    v-for="(header, index) in box.headers"
                    v-bind:key="'header' + index"
                    :style="boxHearderWidth[index]"
                  >
                    <span>{{ header }}</span>
                  </div>
                </div>
                <div
                  class="box_line"
                  v-for="(line, index) in box.lines"
                  v-bind:key="'line' + index"
                >
                  <div class="line_item">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="productDetailList"
            v-if="order.products_detail != null && order.sales_order_type == 2"
            style="display: none; width: 740px; padding-top: 5px"
          >
            <p>{{ order.products_detail.description }}</p>
            <div style="display: table; width: 670px">
              <div class="box_headers">
                <div
                  class="box_header"
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidth[index]"
                >
                  <span>{{ header }}</span>
                </div>
              </div>
              <div
                class="box_line"
                v-for="(line, index) in order.products_detail.lines"
                v-bind:key="'line' + index"
              >
                <div class="line_item">
                  {{ line.product_no }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_description)"
                >
                  {{ line.product_description }}
                </div>
                <div class="line_item">
                  {{ line.uom }}
                </div>
                <div class="line_item">
                  {{ line.qty_ordered }}
                </div>
                <div class="line_item">
                  {{ line.qty_shipped }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_delayed)"
                >
                  {{ line.qty_delayed }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <footer
      id="footer"
      class="text-center text-lg-start bg-light text-muted"
      style="display: none"
    >
      <div class="row">
        <div class="d-flex" style="margin-top: 30px">
          <div style="margin-left: 10px; text-align: left">
            <div class="mb-2 font-weight-bold bold">
              Other OfficeMax Websites
            </div>
            <div><a href="https://www.officemax.co.nz">OfficeMax</a></div>
            <div><a href="https://www.myschool.co.nz">MySchool</a></div>
            <!-- <div><a href="#">Careers at OfficeMax</a></div> -->
          </div>
          <div style="margin-left: 190px; text-align: left">
            <div class="mb-2 font-weight-bold bold">Customer Support</div>
            <div><a href="/faq">Frequent Asked Questions</a></div>
            <div><a href="#">0800 426 473</a></div>
          </div>
        </div>
      </div>
      <div
        class="row justify-content-end"
        style="padding-right: 0px; padding-bottom: 50px; width: 100%"
      >
        <div class="logo"></div>
      </div>
    </footer>
    <!-- Footer -->
    <div id="overlay" style="display: none">
      <img src="../../public/images/gear-wide.svg" alt="Loading..." />
    </div>
  </div>
</template>

<script>
import commonMixin from "./../../public/scripts/commonMixin.js";

export default {
  mixins: [commonMixin],
  // ... other component specific logic
};
</script>

<style scoped>
@import "../../public/stylesheets/style_tablet.css";
</style>
