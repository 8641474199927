import $ from "jquery";
import DateRangePicker from 'vue3-daterange-picker';
export default {
    components: { DateRangePicker },
    data() {
        return {
            orders: [],
            initDateRange: '',
            today_d: '',
            priorDate_d: '',
            orderNum: '',
            custNum: '',
            shipToCode: '',
            selectedStatus: 'All', // This will hold the selected value
            minDate: null,
            maxDate: null,
            showOnly: true, 
            orderStatusOptions:[],
        };
    },
    beforeMount() {
        const today = new Date()
        var priorDate = new Date(new Date().setDate(today.getDate() - 30));
        this.initDateRange = { startDate: priorDate.toString(), endDate: today };
    },
    computed: {
        email() {
            return this.$route.params.email;
        },
        sid() {
            return this.$route.params.sid;
        }
    },
    
    mounted: async function () {
        await this.load();
        if(window.location.href.includes("?")){
            let urlParams = new URLSearchParams(window.location.search);
            let showOnly = urlParams.get('showOnly');
            if(showOnly){
                this.showOnly = showOnly;
            }
            else{
                this.showOnly = true;
            }
        }
        //change head link icon url, remove ..
        let linkIcon = document.querySelector('link[rel="shortcut icon"]');
        if (linkIcon) {
            let currentHref = linkIcon.getAttribute('href');
            if (currentHref.startsWith('..')) {
                let newHref = currentHref.replace('..', '');
                linkIcon.setAttribute('href', newHref);
            }
        }
        
    },
    created: function () {
        this.setDateLimits();
    },
    methods: {
        updateValues(val) {
            console.log('Updated Date Range:', val);
        },
        logEvent(event, data) {
            var date = new Date(data);
            console.log(event);
            if (event === 'startSelection') {
                this.priorDate_d = this.formatDateToDDMMYYYY(date);
                //console.log("Start Date is:" + this.priorDate_d);
            }
            else if (event === 'finishSelection') {
                this.today_d = this.formatDateToDDMMYYYY(date);
                //console.log("End Date is:" + this.today_d);
            }


        },

        setDateLimits() {
            const today = new Date();
            const ninetyDaysAgo = new Date();
            ninetyDaysAgo.setDate(today.getDate() - 90);
      
            this.minDate = ninetyDaysAgo;
            this.maxDate = today;
        }, 

        formatDateToDDMMYYYY(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}-${month}-${year}`;
        },
        convertToDate(str) {
            if(str.split('-').length<2){
                return "";
            }
            var date = new Date(str);
            return this.formatDateToDDMMYYYY(date);
        },
        convertOrderStatus(str) {
            if (str == "90") {
                return "Success"
            }
            else{
                return "Delayed"
            }
        },
        convertDateToDDMMMYYY(date) {
            const monthNames = ["","JAN", "FEB", "MAR", "APR",
                "MAY", "JUN", "JUL", "AUG",
                "SEP", "OCT", "NOV", "DEC"];
            const day = date.split('-')[0];
            const monthIndex = parseInt(date.split('-')[1]);
            const monthName = monthNames[monthIndex];
            const year = date.split('-')[2];
            return `${day}-${monthName}-${year}`;
        },
        changeStyle(e) {
            e.target.classList.remove('border-dark');

        },
        addBorder(event) {
            event.target.classList.add('border-dark');
        },
        async load() {
            
            //init date range from url
            let urlParams = new URLSearchParams(window.location.search);
            let minDate = urlParams.get('minDate');
            let maxDate = urlParams.get('maxDate');            
            if(minDate && maxDate){
                $('.vue-daterange-picker span').text(minDate + " - " + maxDate);
            }

            console.log("Fetching records for.. " + this.email + " and sid " + this.sid);
            this.fetchOrders();
            $('.vue-daterange-picker').next('img').css('cursor','pointer');
            $('.vue-daterange-picker').next('img').off('click').on('click', ()=>{
                setTimeout(() => {
                    $('.vue-daterange-picker span').click();
                    console.log('clicked');
                }, 100);                
            });
            $('.logo-myschool').off('click').on('click', function () {
                window.location.href = 'https://www.myschool.co.nz';          
            });
            $('.logo-omx').off('click').on('click', function () {
                window.location.href = 'https://www.officemax.co.nz';          
            });             
            

        },
        btnSearchClick() {
            let dateRangeText = document.querySelector('.vue-daterange-picker span').textContent;
            let dates = dateRangeText.split(' - ');
            let sdate = dates[0];
            let edate = dates[1];
            this.filterTableRowsByStartEndDateChange(sdate,edate);
        },      
        showOnlyChanged(){
            //redirect to the same page with showOnly query parameter true or false
            let url = window.location.href.split('?')[0];
            let showOnly = this.showOnly;
            if(showOnly){
                url += "?showOnly=true";
            }
            else{
                url += "?showOnly=false";
            }
            //also pass orderNum,custNum,shipToCode,selectedStatus,minDate,maxDate as query parameters
            if(this.orderNum){
                url += "&orderNum=" + this.orderNum;
            }
            if(this.custNum){
                url += "&custNum=" + this.custNum;
            }
            if(this.shipToCode){
                url += "&shipToCode=" + this.shipToCode;
            }
            if(this.selectedStatus){
                url += "&selectedStatus=" + this.selectedStatus;
            }
            let minDate = $('.vue-daterange-picker span').text().split(' - ')[0];
            let maxDate = $('.vue-daterange-picker span').text().split(' - ')[1];
            if(minDate){
                url += "&minDate=" + minDate;
            }
            if(maxDate){
                url += "&maxDate=" + maxDate;
            }
            
            window.location.href = url;
        }
        ,
        resetFullTableRows(){
            var table = document.getElementsByClassName("order-summary-table")[0];
            var rows = table.getElementsByTagName("tr");
            for (let i = 1; i < rows.length; i++) {
                rows[i].style.display = "";
            }
        },
        filterTableRowsByColumnIndex(index, value) {
            
            if(index==4){
                if(value=="All"){
                    value="";
                }
            }
            
            var table = document.getElementsByClassName("order-summary-table")[0];
            var rows = table.getElementsByTagName("tr");
            this.onDateChange();
            for (let i = 1; i < rows.length; i++) {
                var cells = rows[i].getElementsByTagName("td");
                if (cells[index].innerHTML.toLowerCase().indexOf(value.toLowerCase()) == -1) {
                    
                    rows[i].style.display = "none";
                }
            }
        },
        showOnlyMyChanges(){
            if(this.showOnly){            
                var email = this.email;
                var table = document.getElementsByClassName("order-summary-table")[0];
                var rows = table.getElementsByTagName("tr");
                this.onDateChange();
                for (let i = 1; i < rows.length; i++) {
                    var cells = rows[i].getElementsByTagName("td");
                    if (cells[5].innerHTML.toLowerCase().indexOf(email.toLowerCase()) == -1) {
                        
                        rows[i].style.display = "none";
                    }
                }
            }
        },
        strToDate(str) {
            const dateString = str;
            const [day, month, year] = dateString.split('-');
            const date = new Date(year, month - 1, day);    
            return date;        
        },
        onDateRangeChange(){
            this.resetFullTableRows();
            this.filterTableRowsByColumnIndex(2, this.orderNum);
            this.filterTableRowsByColumnIndex(3, this.custNum);
            this.filterTableRowsByColumnIndex(6, this.shipToCode);
            this.filterTableRowsByColumnIndex(4, this.selectedStatus);
            this.showOnlyMyChanges();
            this.onDateChange(); 
        },
        onDateChange() {
            let from = $('.vue-daterange-picker span').text().split(' - ')[0];
            let to = $('.vue-daterange-picker span').text().split(' - ')[1];
            const start = new Date(from.split('-')[2],parseInt(from.split('-')[1])-1,from.split('-')[0]);
            const end = new Date(to.split('-')[2],parseInt(to.split('-')[1])-1,to.split('-')[0]);
            this.filterTableRowsByStartEndDateChange(start,end);
        },
        filterTableRowsByStartEndDateChange(sdate,edate) {
            var table = document.getElementsByClassName("order-summary-table")[0];
            var rows = table.getElementsByTagName("tr");
            for (var i = 1; i < rows.length; i++) {
                var cells = rows[i].getElementsByTagName("td");
                if (this.strToDate(cells[0].innerHTML.toLowerCase()) <sdate) {
                    rows[i].style.display = "none";
                }
                if(this.strToDate(cells[0].innerHTML.toLowerCase()) > edate){
                    rows[i].style.display = "none";
                }
                // else {
                //     rows[i].style.display = "";
                // }
            }
        },
        fetchOrders() {
            this.showSpinner()

            //generate a random token            
            if(sessionStorage.getItem('token') === null) 
            {
                let token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                sessionStorage.setItem('token', token);
            }
            let fsid = sessionStorage.getItem('token');
            var baseUrl = process.env.VUE_APP_FE_URL;
            let customerOrderUrl = baseUrl + "/int/v1.0/customer/order?sid=" + this.sid + "&email=" + this.email + "&showOnly=" + this.showOnly;
            console.log(customerOrderUrl)
            fetch(customerOrderUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': 'Bearer ' + fsid
                }
            })
            .then((response) => response.json())
            .then((res) => {
                console.log(res);

                //fake options if not existing
                if(!res.OrderStatusOptions){
                    res.OrderStatusOptions = [
                        {"Key":"90","Value":"success"},
                        {"Key":"70","Value":"deplay"},
                    ];
                }

                this.orders = res.CustomerOrderDetails;
                this.orderStatusOptions = res.OrderStatusOptions;
                // this.orderStatusOptions.push({"Key":"","Value":"All"});
                
                this.hideSpinner();
                
                document.getElementById("omxOrderNo").addEventListener("input", () => {
                    this.resetFullTableRows();
                    this.filterTableRowsByColumnIndex(2, this.orderNum);
                    this.filterTableRowsByColumnIndex(3, this.custNum);
                    this.filterTableRowsByColumnIndex(6, this.shipToCode);
                    this.filterTableRowsByColumnIndex(4, this.selectedStatus);
                    this.showOnlyMyChanges();
                });
                document.getElementById("refNo").addEventListener("input", () => {
                    this.resetFullTableRows();
                    this.filterTableRowsByColumnIndex(2, this.orderNum);
                    this.filterTableRowsByColumnIndex(3, this.custNum);
                    this.filterTableRowsByColumnIndex(6, this.shipToCode);
                    this.filterTableRowsByColumnIndex(4, this.selectedStatus);
                    this.showOnlyMyChanges();
                });                
                document.getElementById("shipToCode").addEventListener("input", () => {
                    this.resetFullTableRows();
                    this.filterTableRowsByColumnIndex(2, this.orderNum);
                    this.filterTableRowsByColumnIndex(3, this.custNum);
                    this.filterTableRowsByColumnIndex(6, this.shipToCode);
                    this.filterTableRowsByColumnIndex(4, this.selectedStatus);
                    this.showOnlyMyChanges();
                });
                document.getElementById("orderStatus").addEventListener("change", () => {                        
                    //var selectedText = event.target.options[event.target.selectedIndex].text;
                    this.resetFullTableRows();
                    this.filterTableRowsByColumnIndex(2, this.orderNum);
                    this.filterTableRowsByColumnIndex(3, this.custNum);
                    this.filterTableRowsByColumnIndex(6, this.shipToCode);
                    this.filterTableRowsByColumnIndex(4, this.selectedStatus);
                    this.showOnlyMyChanges();
                }); 

            }).then(() => {
                $.fn.dataTable.ext.type.order['custom-date-dd-mm-yyyy-pre'] = function(date) {
                    if(date){
                        var parts = date.split('-');
                        return new Date(parts[2], parts[1] - 1, parts[0]).getTime();
                    }
                    else{
                        return null;
                    }
                    
                  };

                $("#dtOrders").dataTable({
                  columnDefs: [
                    { type: 'custom-date-dd-mm-yyyy', targets: [0,1] }
                    ],
                  paging: false,
                  pageLength: 10,
                  "dom": 't'
                });
              }).then(()=>{
                this.onDateChange();                           
              }).then(()=>{
                //set orderNum,custNum,shipToCode,selectedStatus,minDate,maxDate from query parameters
                let urlParams = new URLSearchParams(window.location.search);
                let orderNum = urlParams.get('orderNum');
                let custNum = urlParams.get('custNum');
                let shipToCode = urlParams.get('shipToCode');
                let selectedStatus = urlParams.get('selectedStatus');
                let minDate = urlParams.get('minDate');
                let maxDate = urlParams.get('maxDate');
                let showOnly = urlParams.get('showOnly')!="false";
                console.log(orderNum,custNum,shipToCode,selectedStatus,minDate,maxDate,showOnly);
                if(minDate && maxDate){
                    $('.vue-daterange-picker span').text(minDate + " - " + maxDate);
                    this.onDateChange();
                }
                if(orderNum){
                    this.orderNum = orderNum;
                    //trigger filterTableRowsByColumnIndex
                    this.filterTableRowsByColumnIndex(2, orderNum);
                }
                if(custNum){
                    this.custNum = custNum;
                    //trigger filterTableRowsByColumnIndex
                    this.filterTableRowsByColumnIndex(3, custNum);
                }
                if(shipToCode){
                    this.shipToCode = shipToCode;
                    //trigger filterTableRowsByColumnIndex
                    this.filterTableRowsByColumnIndex(6, shipToCode);
                }
                if(selectedStatus){
                    this.selectedStatus = selectedStatus;
                    //trigger filterTableRowsByColumnIndex
                    this.filterTableRowsByColumnIndex(4, selectedStatus);
                }
                if(showOnly){
                    this.showOnly = true;                    
                }
                else{
                    this.showOnly = false;
                }
                
              }).then(()=>{
                if(this.showOnly){
                    this.showOnlyMyChanges();
                }     
              });
        },
        showSpinner() {
            const overlay = document.getElementById('overlay');
            overlay.style.display = 'flex'; // Show the spinner
        },

        hideSpinner() {
            const overlay = document.getElementById('overlay');
            overlay.style.display = 'none'; // Hide the spinner
        }
    },
    watch: {
        selectedStatus(newValue) {
            console.log('Selected Status:', newValue); // React to changes
        }
    }

};