import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeViewNew.vue'
// import HomeView from '../views/OrdersSearch.vue'
import DesktopView from '../views/DesktopView.vue'
import TestView from '../views/TestView.vue'
import LoginView from '../views/LoginView.vue'
import AdminView from '../views/AdminView.vue'
import AdminEditView from '../views/AdminEditView.vue'
import FAQView from '../views/FAQView.vue'
import FAQMobileView from '../views/FAQMobileView.vue'
import CustomerOrdersView from '../views/CustomerOrdersView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/order-tracking/:order_no',
    name: 'orderTracking',
    component:DesktopView
  },
  {
    path: '/loginLegacy',
    name: 'loginLagacy',
    component: LoginView
  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  },
  {
    path: '/guid/:guid',
    name: 'homeGuid',
    component: HomeView
  },
  {
    path: '/admin',
    name: 'admin',
    component:AdminView
  },
  {
    path: '/admin/config/:config_id',
    name: 'configEdit',
    component:AdminEditView
  },
  {
    path: '/faq',
    name: 'FAQ',
    component:FAQView
  },
  {
    path: '/faqm',
    name: 'FAQM',
    component:FAQMobileView
  }   
  ,
  {
    path: '/cust/orders/:email/:sid',
    name: 'custOrders',
    component:CustomerOrdersView
  }   
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const path = to.path;
  if (path !== '/' && path.endsWith('/')) {
      next(path.slice(0, -1));
  } else {
      next(); 
  }
});

export default router
