<template>
  <div
    class="masthead-wrapper"
    style="
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
    "
  >
    <div
      class="container masthead masthead-tablet"
      style="
        display: flex;
        /* width: 360px; */
        align-items: center;
        justify-content: center;
        padding: 0px 0px !important;
        margin: 0px 0px !important;
      "
    >
      <div class="logo-mobile" style="height: 25px; margin-left: 0px"></div>
      <div class="logo_title-mobile" style="height: 25px">Order Tracking</div>
      <div style="float: left; margin-left: 20px; display: flex; height: 25px">
        <a href="tel:0800426473">
          <img
            style="width: 25px; height: 25px; float: right"
            src="../../public/images/phone.png"
          />
        </a>
        <img
          style="width: 25px; height: 25px; float: right"
          src="../../public/images/question.png"
          v-on:click="navigateToUrl('/faq')"
        />
        <div
          class="login"
          id="btnLogin"
          style="font-size: 10px; height: 25px; width: 60px; margin-left: 8px"
          v-on:click="btnLoginClick"
          v-if="ifGuid == false"
        >
          <div style="">log in</div>
        </div>
        <div
          class="login"
          id="btnLogout"
          style="
            height: 25px;
            margin-left: 8px;
            display: none;
            font-size: 10px;
            width: auto;
          "
          v-on:click="btnLogoutClick"
        >
          <div
            style="
              padding: 2px 4px;
              border-radius: 10px;
              border: white 1px solid;
              width: 50px;
            "
          >
            log out
          </div>
        </div>
        <!-- <img id="btnLogin"  v-on:click="btnLoginClick" style="width:18px;margin-left: 2px;" src="../../public/images/login.svg"> -->
      </div>

      <!-- <div class="emailAddr" style="margin-left: 60px;">{{ session.email }}</div> -->
    </div>
    <!-- <div class="login" id="btnLogin" style="" v-on:click="btnLoginClick">
                <div>log in</div>
            </div>    -->
  </div>
  <div style="display: flex; justify-content: center; align-items: center">
    <h3 v-if="orders.length > 0" style="width: 360px" class="header1-mobile">
      Track your order
      <span v-if="orders.length > 0">{{ orders[0].so_order_no }}</span>
      <img
        class="btnShareMobile"
        v-if="customer.guid_url != null && ifGuid == false"
        v-bind:link="customer.guid_url"
        v-on:click="btnShareMobileClick"
        style="margin-left: 30px"
        src="../../public/images/External.png"
      />
    </h3>
    <h5
      style="display: none; float: right; margin-right: 25px; margin-top: -15px"
      id="linkCopied"
    >
      link copied!
    </h5>
  </div>

  <div style="display: flex; justify-content: center; align-items: center">
    <div
      v-if="orders.length > 0 && alertInfo != null"
      class="alertArea-mobile"
      style="width: 360px"
    >
      <img src="../../public/images/Subtract.svg" />
      <p style="padding: 10px; margin-top: -30px; margin-left: 20px">
        {{ alertInfo }}
      </p>
    </div>
  </div>
  <div style="display: flex; justify-content: center; align-items: center">
    <div style="margin-left: 0px auto; float: left">
      <div
        v-if="session.token.length > 1 && ifGuid == false"
        style="width: 360px"
        class="info_area-mobile"
      >
        <h4>Search by OfficeMax Order Number</h4>
        <h5>eg:12345678</h5>
        <div id="searchbar">
          <div
            id="search_input"
            style="display: flex"
            :class="{ 'active-border': inputFocused }"
          >
            <div style="float: left">
              <input
                type="text"
                style="width: 260px"
                id="terms"
                autocomplete="off"
                name="Ntt"
                class="text_box"
                value=""
                @keyup.enter="btnSearchClick"
                @focus="inputFocused = true"
                @blur="inputFocused = false"
                maxlength="8"
                oninput="this.value = this.value.replace(/\D/g, '')"
                v-model="orderNoBind"
              />
            </div>
            <div style="float: left">
              <button
                class="new_search new_search-mobile"
                value="Search"
                id="btnSearch"
                v-on:click="btnSearchClick"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                  src="../../public/images/search.svg"
                  alt="Search"
                  style="width: 13px; height: 13px"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style="display: none; width: 343px" id="search-alert">
        <div class="img"></div>
        <p></p>
      </div>
      <div id="right_panel" style="display: none">
        <div class="tab" style="width: 343px; height: 28px; display: flex">
          <div
            class="tab_header"
            id="tab_header_cus"
            @click="tab_header_cus_click"
            style="font-weight: 600; text-decoration: none"
          >
            Customer Details
          </div>
          <div
            class="tab_header"
            id="tab_header_del"
            @click="tab_header_del_click"
            style="font-weight: 300; text-decoration: underline"
          >
            Delivery Details
          </div>
        </div>
        <div style="display: flex">
          <div
            style="width: 360px; float: left; padding: 15px"
            class="info_area"
            id="area_customer"
          >
            <div class="block" style="width: 330px">
              <div class="line-mobile" v-if="customer.customer_number != null">
                <div class="bold" style="color: #657e91">Customer Number</div>
                <div id="custNoCustName">{{ customer.customer_number }}</div>
              </div>
              <div class="line-mobile" v-if="customer.customer_name != null">
                <div class="bold" style="color: #657e91">Customer Name</div>
                <div id="custNoCustName">{{ customer.customer_name }}</div>
              </div>
              <div
                class="line-mobile"
                v-if="customer.officemax_order_number != null"
              >
                <div class="bold" style="color: #657e91">
                  OfficeMax Order Number
                </div>
                <div id="orderedBy">{{ customer.officemax_order_number }}</div>
              </div>
              <div
                class="line-mobile"
                v-if="customer.customer_order_number != null"
              >
                <div class="bold" style="color: #657e91">
                  Customer Order Number
                </div>
                <div id="orderedBy">{{ customer.customer_order_number }}</div>
              </div>
              <div class="line-mobile" v-if="customer.order_by != null">
                <div class="bold" style="color: #657e91">Ordered By</div>
                <div id="orderedBy">{{ customer.order_by }}</div>
              </div>
              <div class="line-mobile" v-if="customer.order_date != null">
                <div class="bold" style="color: #657e91">Ordered On</div>
                <div id="orderedOn">
                  {{ formatDateToOct(customer.order_date) }}
                </div>
              </div>
              <div class="line-mobile" v-if="customer.order_phone != null">
                <div class="bold" style="color: #657e91">Orderer Phone</div>
                <div id="customerReference">{{ customer.order_phone }}</div>
              </div>
              <div class="line-mobile" v-if="customer.order_email != null">
                <div class="bold" style="color: #657e91">Orderer Email</div>
                <div id="customerReference">{{ customer.order_email }}</div>
              </div>
              <div class="line-mobile" v-if="customer.payment_method != null">
                <div class="bold" style="color: #657e91">Payment Method</div>
                <div id="customerReference">{{ customer.payment_method }}</div>
              </div>
            </div>
          </div>
          <div
            style="width: 360px; float: left; padding: 15px; display: none"
            class="info_area"
            id="area_delivery"
          >
            <div class="block" style="width: 330px">
              <div class="line-mobile bold">
                <div style="color: #657e91">Delivery Address:</div>
              </div>
              <div class="line-mobile address">
                <div
                  id="shippingAddress"
                  v-html="customer.shipping_address"
                ></div>
              </div>
            </div>
            <div class="block" style="width: 330px">
              <div class="line-mobile bold">
                <div style="color: #657e91">Special Delivery Instruction:</div>
              </div>
              <div class="line-mobile address">
                <div
                  id="specialIns"
                  v-html="customer.special_delivery_instruction"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="width: 360px"
          class="ship_info-mobile"
          v-if="shipInfo != null"
        >
          <img
            src="../../public/images/SubtractBlue.svg"
            style="margin-right: 10px; width: 18px"
          />
          <p
            class="packageInfo-mobile"
            style="
              width: 300px;
              display: block;
              margin-top: -30px;
              margin-left: 25px;
            "
          >
            {{ shipInfo }}
          </p>
        </div>
        <p
          v-if="orders.length > 0"
          class="packageInfo-mobile"
          style="
            width: 300px;
            display: block;
            margin-top: 10px;
            margin-left: 13px;
          "
        >
          <img
            src="../../public/images/SubtractBlue.svg"
            style="margin-right: 5px; width: 18px"
          />{{ orders.length }} part(s) in OfficeMax Order Number
          {{ orders[0].so_order_no }}
        </p>
      </div>

      <div
        class="orderArea"
        v-for="(order, index) in orders"
        v-bind:key="order.id"
        :class="`order${index + 1}`"
      >
        <div
          v-if="order.sales_order_type == 0"
          style="width: 360px; position: relative; margin-bottom: 10px"
          class="info_area"
        >
          <div
            style="
              width: 330px;
              height: 30px;
              position: relative;
              margin-bottom: 20px;
            "
          >
            <div
              class="orderTitle"
              style="
                width: 172px;
                height: 26px;
                margin-top: -33px;
                margin-left: -35px;
                background-color: #94aaba !important;
              "
            >
              {{ order.so_warehouse }}
            </div>
          </div>
          <div
            class="orderDetail-mobile"
            style="
              padding-top: 0px;
              font-size: 11px !important;
              font-weight: normal;
            "
          >
            <div class="bold" style="width: 300px">
              OfficeMax Order Number: {{ order.so_order_no }}
            </div>
            <div style="width: 300px; display: flex">
              <div v-if="order.so_order_suffix != null">
                <span class="bold">Part: </span>{{ order.so_order_suffix }}
              </div>
              <span class="splitter" v-if="order.so_order_suffix != null"
                >|</span
              >
              <div v-if="order.packages_in_batch != null">
                <span class="bold">Boxes in this part: </span
                >{{ order.packages_in_batch }}
              </div>
            </div>
            <!-- <div style="width: 300px">
              <span class="bold">Warehouse: </span>{{ order.so_warehouse }}
            </div> -->
            <div style="width: 300px">
              <span class="bold">Carrier: </span>{{ order.so_carrier }}
            </div>
          </div>
          <div style="padding-top: 10px; margin-left: 15px">
            <div
              v-if="order.full_tracking_url != null"
              :class="getStyleObjectMobile(order.is_all_delivered)"
              style="
                text-align: center;
                margin-left: 112px !important;
                width: 196px !important;
              "
            >
              <a v-bind:href="order.full_tracking_url" target="_blank"
                >Check Courier Status</a
              >
            </div>
          </div>

          <div class="line-mobile" style="margin-top: 20px; width: 330px">
            <div class="withOfficeMax">With OfficeMax</div>
          </div>
          <div class="line-mobile" style="margin-top: 20px; position: relative">
            <div class="tooltip tooltip-mobile" style="display: none">
              <p class="tooltipContent">xxxxxxxxxxxxxxxx</p>
            </div>
            <div
              class="tooltipBoxNumber tooltipBoxNumber-mobile"
              style="display: none"
            >
              <p class="tooltipContent">
                This indicates the number of boxes at this stage.
              </p>
            </div>
            <div
              style="width: 360px; display: block; z-index: 2"
              class="imgDivs"
            >
              <div class="imgDiv-mobile">
                <!-- <div style="width:100px;" class="withOfficeMax">With OfficeMax</div>                                                     -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[0]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status1" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile" style="font-size: 12px">
                  {{ order.status_name[0] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[0] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile">
                <div class="line1-mobile"></div>
              </div>
              <div class="imgDiv-mobile">
                <!-- <div style="width:100px;" class="withBlank" >&nbsp;</div> -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[1]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status2" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[1] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[1] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile">
                <div class="line1-mobile"></div>
              </div>
              <div class="imgDiv-mobile">
                <!-- <div style="width:100px;" class="withBlank">&nbsp;</div>                     -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[2]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status3" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[2] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 12px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[2] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile">
                <div class="line1-mobile"></div>
              </div>
              <div
                class="withCarrier"
                style="margin-top: 15px; margin-left: -133px; z-index: 2"
              >
                With Carrier
              </div>
              <div style="position: relative">
                <div class="brownAreaOverlay-mobile" style="z-index: 1"></div>
              </div>

              <div class="imgDiv-mobile brownOverlay" style="z-index: 2">
                <!-- <div style="width:90px;" class="withCarrier">With Carrier</div> -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[3]"
                  style="
                    text-align: center;
                    width: 35px;
                    height: 35px;
                    z-index: 2;
                  "
                >
                  <img style="z-index: 2" class="status4" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[3] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[3] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile brownOverlay">
                <div class="line1-mobile"></div>
              </div>
              <div class="imgDiv-mobile brownOverlay">
                <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[4]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status5" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[4] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[4] }}&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div style="display: grid">
            <div
              class="btnOfficeMax btnOrderEmpty-mobile"
              v-bind:data-orderNo="order.so_order_no"
              v-bind:data-prefix="order.so_order_suffix"
              v-on:click="btnOrderEmptyClick"
              v-if="order.is_send_delivery_paperwork == true"
            >
              <a href="#">Send Delivery Paperwork</a>
            </div>
            <div
              class="btnOfficeMax btnOrder-mobile btnStudentDetails"
              style="opacity: 1; margin-top: 10px"
              v-if="customer.is_myschool == 'Y'"
            >
              <a href="#">View By Product</a>
            </div>
            <div
              class="btnOfficeMax btnOrder-mobile btnProductDetails"
              style="opacity: 1; margin-top: 10px"
              v-if="order.products_detail != null"
            >
              <a href="#">View By Product</a>
            </div>
            <div
              class="btnOfficeMax btnOrder-mobile btnBoxDetails"
              style="opacity: 1; margin-top: 10px"
              v-if="order.box_details != null && customer.is_myschool != 'Y'"
            >
              <a href="#">View Box Summary</a>
            </div>
          </div>
        </div>        
        <div
          class="boxDetailList"
          v-if="
            order.box_details != null &&
            order.box_details.length > 0 &&
            order.sales_order_type == 0
          "
          style="
            display: none;
            width: 360px;
            padding-top: 5px;
            margin-top: 5px;
            margin-bottom: 10px;
            overflow-x: scroll;
          "
        >
          <p>Box Total : {{ order.box_details.length }}</p>
          <div
            v-for="box in order.box_details"
            v-bind:key="box.package_id"
            style="width: 305px"
          >
            <div
              style="
                width: 305px;
                /* display: flex; */
                /* align-items: center; */
                margin-top: 10px;
              "
            >
              <p
                class="boxStatus"
                style="
                  display: block;
                  float: left;
                  margin-right: 180px;
                  width: 305px;
                "
              >
                Box Status : <span>{{ box.status }}</span>
              </p>
            </div>
            <div
              class="scrollableArea"
              style="width: 305px; overflow-x: scroll"
              v-on:scroll="arrowCheck"
            >
              <div
                style="
                  display: table;
                  width: 305px;
                  table-layout: fixed;
                  width: 490px;
                "
              >
                <div class="box_headers">
                  <div
                    :class="
                      index === 0
                        ? 'box_header colFixed'
                        : index === 1
                        ? 'box_header colFixed1'
                        : 'box_header'
                    "
                    v-for="(header, index) in box.headers"
                    v-bind:key="'header' + index"
                    :style="boxHearderWidthMobile[index]"
                  >
                    <span>{{ header }}</span>
                  </div>
                </div>
                <div
                  class="box_line"
                  v-for="(line, index) in box.lines"
                  v-bind:key="'line' + index"
                >
                  <div class="line_item colFixed">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item colFixed1"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="scrollWrapper"
              style="width: 304px; display: flex; align-items: center"
            >
              <div
                style="
                  width: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: left;
                "
              >
                <span
                  class="scrollLeft"
                  style="color: grey"
                  v-on:click="scrollLeft"
                  >＜</span
                >
              </div>
              <div
                style="
                  width: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: right;
                "
              >
                <span
                  class="scrollRight"
                  style="color: #33363f"
                  v-on:click="scrollRight"
                  >＞</span
                >
              </div>
            </div>

            <div
              v-if="box.tracking_url != null"
              :class="getStyleObject(box.is_delivered)"
              style="
                width: 305px !important;
                height: 35px;
                margin-left: 0px !important;
                margin-top: 10px !important;
              "
            >
              <a v-bind:href="box.tracking_url" target="_blank"
                >Check Courier Status</a
              >
            </div>
          </div>
        </div>
        <div
          class="productDetailList"
          v-if="order.products_detail != null && order.sales_order_type == 0"
          style="
            display: none;
            width: 360px;
            padding-top: 5px;
            margin-top: 5px;
            margin-bottom: 10px;
          "
        >
          <p>{{ order.products_detail.description }}</p>
          <div
            style="width: 304px;display: flex;align-items: center;justify-content: center;margin: 10px 0px;padding: 5px 10px;"
            class="ship_info-mobile"            
          >
            <img
              src="../../public/images/SubtractBlue.svg"
              style="margin-right: 10px; width: 18px"
            />
            <p
              class="packageInfo-mobile"
              style="
                width: 300px;
                display: block;   
                margin: 0px;
                padding: 0px;  
                font-weight: 500;           
              "
            >
              Swipe left to view quantities
            </p>
          </div>
          <div
            class="scrollableArea"
            style="width: 305px; overflow-x: scroll"
            v-on:scroll="arrowCheck"
          >
            <div style="display: table; table-layout: fixed; width: 490px">
              <div class="box_headers">
                <div
                  :class="
                    index === 0
                      ? 'box_header colFixed'
                      : index === 1
                      ? 'box_header colFixed1'
                      : 'box_header'
                  "
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidthMobile[index]"
                >
                  <span>{{ header }}</span>
                </div>
              </div>
              <div
                class="box_line"
                v-for="(line, index) in order.products_detail.lines"
                v-bind:key="'line' + index"
              >
                <div class="line_item colFixed">
                  {{ line.product_no }}
                </div>
                <div
                  class="line_item colFixed1"
                  :style="getColorStyle(line.text_color_description)"
                >
                  {{ line.product_description }}
                </div>
                <div class="line_item">
                  {{ line.uom }}
                </div>
                <div class="line_item">
                  {{ line.qty_ordered }}
                </div>
                <div class="line_item">
                  {{ line.qty_shipped }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_delayed)"
                >
                  {{ line.qty_delayed }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="scrollWrapper"
            style="width: 304px; display: flex; align-items: center"
          >
            <div
              style="
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: left;
              "
            >
              <span
                class="scrollLeft"
                style="color: grey"
                v-on:click="scrollLeft"
                >＜</span
              >
            </div>
            <div
              style="
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: right;
              "
            >
              <span
                class="scrollRight"
                style="color: #33363f"
                v-on:click="scrollRight"
                >＞</span
              >
            </div>
          </div>
        </div>
        <div
          class="studentDetailList"
          v-if="order.products_detail != null && order.sales_order_type == 0"
          style="
            display: none;
            width: 360px;
            padding-top: 5px;
            margin-top: 5px;
            margin-bottom: 10px;
          "
        >
          
          <div
            style="width: 100%;display: flex;align-items: center;justify-content: center;margin: 10px 0px;padding: 5px 10px;"
            class="ship_info-mobile"            
          >
            <img
              src="../../public/images/SubtractBlue.svg"
              style="margin-right: 10px; width: 18px"
            />
            <p
              class="packageInfo-mobile"
              style="
                width: 300px;
                display: block;   
                margin: 0px;
                padding: 0px;  
                font-weight: 500;    
                background-color: #e3f7ff;       
              "
            >
              Swipe left to view quantities
            </p>
          </div>
          <template v-for="(student, index) in order.students_detail" v-bind:key="'student' + index"> 
            <div style="background-color: white;padding: 10px 20px;border-radius: 5px;margin: 30px 0px;">
              <p style="width: 340px;margin-top: 0px;">
                  Student Name :&nbsp; <span class="studentName">{{ student.student_name}}</span>
              </p> 
              <div              
              class="scrollableArea"
              style="width: 100%; overflow-x: scroll"
              v-on:scroll="arrowCheck"
              >    
                <div style="display: table; table-layout: fixed; width: 490px;">         
                  <div class="box_headers">
                    <div
                      :class="
                        index === 0
                          ? 'box_header colFixed'
                          : index === 1
                          ? 'box_header colFixed1'
                          : 'box_header'
                      "
                      v-for="(header, index) in order.products_detail.headers"
                      v-bind:key="'header' + index"
                      :style="boxHearderWidthMobile[index]"
                    >
                      <span>{{ header }}</span>
                    </div>
                  </div>
                  <div                  
                    class="box_line"                  
                    v-for="(line, index) in student.StudentProductLines" v-bind:key="'product' + index"
                  >
                    <div class="line_item colFixed">
                      {{ line.product_no }}
                    </div>
                    <div
                      class="line_item colFixed1"
                      :style="getColorStyle(line.text_color_description)"
                    >
                      {{ line.product_description }}
                    </div>
                    <div class="line_item">
                      {{ line.uom }}
                    </div>
                    <div class="line_item">
                      {{ line.qty_ordered }}
                    </div>
                    <div class="line_item">
                      {{ line.qty_shipped }}
                    </div>
                    <div
                      class="line_item"
                      :style="getColorStyle(line.text_color_delayed)"
                    >
                      {{ line.qty_delayed }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                  
                  class="scrollWrapper"
                  style="width: 323px; display: flex; align-items: center;margin-top: 10px;margin-bottom: 30px;"
                >
                  <div
                    style="
                      width: 50%;
                      display: flex;
                      align-items: center;
                      justify-content: left;
                    "
                  >
                    <span
                      class="scrollLeft"
                      style="color: grey"
                      v-on:click="scrollLeft"
                      >＜</span
                    >
                  </div>
                  <div
                    style="
                      width: 50%;
                      display: flex;
                      align-items: center;
                      justify-content: right;
                    "
                  >
                    <span
                      class="scrollRight"
                      style="color: #33363f"
                      v-on:click="scrollRight"
                      >＞</span
                    >
                  </div>
              </div>
            </div>
          </template>
        </div>

        <div
          v-if="order.sales_order_type == 1"
          style="width: 360px; position: relative; margin-bottom: 10px"
          class="info_area"
        >
          <div
            style="
              width: 330px;
              height: 30px;
              position: relative;
              margin-bottom: 20px;
            "
          >
            <div
              class="orderTitle"
              style="
                width: 172px;
                height: 26px;
                margin-top: -33px;
                margin-left: -35px;
              "
            >
              Direct Ship
            </div>
          </div>
          <div
            class="orderDetail-mobile"
            style="
              padding-top: 0px;
              font-size: 11px !important;
              font-weight: normal;
            "
          >
            <div class="bold" style="width: 300px">
              OfficeMax Order Number: {{ order.so_order_no }}
            </div>
            <div style="width: 300px; display: flex">
              <div v-if="order.so_order_suffix != null">
                <span class="bold">Part: </span>{{ order.so_order_suffix }}
              </div>
              <div><span class="bold">&nbsp; </span></div>
            </div>
            <div style="width: 300px"><span class="bold">&nbsp; </span></div>
            <div style="width: 300px"><span class="bold">&nbsp; </span></div>
          </div>
          <div class="line-mobile" style="margin-top: 20px; width: 360px">
            <div
              class="withSupplier"
              style="background-color: #007fc7; opacity: 1"
            >
              With Supplier
            </div>
          </div>
          <div class="line-mobile" style="margin-top: 20px; position: relative">
            <div class="tooltip tooltip-mobile" style="display: none">
              <p class="tooltipContent">xxxxxxxxxxxxxxxx</p>
            </div>
            <div
              class="tooltipBoxNumber tooltipBoxNumber-mobile"
              style="display: none"
            >
              <p class="tooltipContent">
                This indicates the number of boxes at this stage.
              </p>
            </div>
            <div style="width: 360px; z-index: 2" class="imgDivs">
              <div class="imgDiv-mobile">
                <!-- <div style="width:100px;" class="withOfficeMax">With OfficeMax</div>                                                     -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[0]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status1" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[0] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[0] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile">
                <div class="line1-mobile"></div>
              </div>
              <div class="imgDiv-mobile">
                <!-- <div style="width:100px;" class="withBlank" >&nbsp;</div> -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[1]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status2" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[1] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[1] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile">
                <div class="line1-mobile"></div>
              </div>
              <div class="imgDiv-mobile brownOverlay">
                <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[2]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status3" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[2] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[2] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile">
                <div class="line1-mobile"></div>
              </div>

              <div
                class="withCarrier"
                style="margin-top: 15px; margin-left: -133px; z-index: 2"
              >
                With Carrier
              </div>
              <div style="position: relative">
                <div
                  class="brownAreaOverlay-mobile"
                  style="z-index: 1; height: 150px !important"
                ></div>
              </div>

              <div class="imgDiv-mobile brownOverlay" style="z-index: 2">
                <!-- <div style="width:90px;" class="withCarrier">With Carrier</div> -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[3]"
                  style="
                    text-align: center;
                    width: 35px;
                    height: 35px;
                    z-index: 2;
                  "
                >
                  <img style="z-index: 2" class="status5" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[3] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[3] }}&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div style="display: grid; margin-top: 70px">
            <div
              class="btnOfficeMax btnOrderEmpty-mobile"
              v-bind:data-orderNo="order.so_order_no"
              v-bind:data-prefix="order.so_order_suffix"
              v-on:click="btnOrderEmptyClick"
              v-if="order.is_send_delivery_paperwork == true"
            >
              <a href="#">Send Delivery Paperwork</a>
            </div>
            <div
              class="btnOfficeMax btnOrder-mobile btnStudentDetails"
              style="opacity: 1; margin-top: 10px"
              v-if="customer.is_myschool == 'Y'"
            >
              <a href="#">View By Product</a>
            </div>
            <div
              class="btnOfficeMax btnOrder-mobile btnProductDetails"
              style="opacity: 1; margin-top: 10px"
              v-if="order.products_detail != null"
            >
              <a href="#">View By Product</a>
            </div>
            <div
              class="btnOfficeMax btnOrder-mobile btnBoxDetails"
              style="opacity: 1; margin-top: 10px"
              v-if="order.box_details != null && customer.is_myschool != 'Y'"
            >
              <a href="#">View Box Summary</a>
            </div>
          </div>
        </div>
        <div
          class="boxDetailList"
          v-if="
            order.box_details != null &&
            order.box_details.length > 0 &&
            order.sales_order_type == 1
          "
          style="
            display: none;
            width: 360px;
            padding-top: 5px;
            margin-top: 5px;
            margin-bottom: 10px;
            overflow-x: scroll;
          "
        >
          <p>Box Total : {{ order.box_details.length }}</p>
          <div
            v-for="box in order.box_details"
            v-bind:key="box.package_id"
            style="width: 305px"
          >
            <div
              style="
                width: 305px;
                /* display: flex; */
                /* align-items: center; */
                margin-top: 10px;
              "
            >
              <p
                class="boxStatus"
                style="
                  display: block;
                  float: left;
                  margin-right: 180px;
                  width: 305px;
                "
              >
                Box Status : <span>{{ box.status }}</span>
              </p>
            </div>
            <div
              class="scrollableArea"
              style="width: 305px; overflow-x: scroll"
              v-on:scroll="arrowCheck"
            >
              <div
                style="
                  display: table;
                  width: 305px;
                  table-layout: fixed;
                  width: 490px;
                "
              >
                <div class="box_headers">
                  <div
                    :class="
                      index === 0
                        ? 'box_header colFixed'
                        : index === 1
                        ? 'box_header colFixed1'
                        : 'box_header'
                    "
                    v-for="(header, index) in box.headers"
                    v-bind:key="'header' + index"
                    :style="boxHearderWidthMobile[index]"
                  >
                    <span>{{ header }}</span>
                  </div>
                </div>
                <div
                  class="box_line"
                  v-for="(line, index) in box.lines"
                  v-bind:key="'line' + index"
                >
                  <div class="line_item colFixed">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item colFixed1"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="scrollWrapper"
              style="width: 304px; display: flex; align-items: center"
            >
              <div
                style="
                  width: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: left;
                "
              >
                <span
                  class="scrollLeft"
                  style="color: grey"
                  v-on:click="scrollLeft"
                  >＜</span
                >
              </div>
              <div
                style="
                  width: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: right;
                "
              >
                <span
                  class="scrollRight"
                  style="color: #33363f"
                  v-on:click="scrollRight"
                  >＞</span
                >
              </div>
            </div>

            <div
              v-if="box.tracking_url != null"
              :class="getStyleObject(box.is_delivered)"
              style="
                width: 305px !important;
                height: 35px;
                margin-left: 0px !important;
                margin-top: 10px !important;
              "
            >
              <a v-bind:href="box.tracking_url" target="_blank"
                >Check Courier Status</a
              >
            </div>
          </div>
        </div>
        <div
          class="productDetailList"
          v-if="order.products_detail != null && order.sales_order_type == 1"
          style="
            display: none;
            width: 360px;
            padding-top: 5px;
            margin-top: 5px;
            margin-bottom: 10px;
          "
        >
          <p>{{ order.products_detail.description }}</p>
          <div
            style="width: 304px;display: flex;align-items: center;justify-content: center;margin: 10px 0px;padding: 5px 10px;"
            class="ship_info-mobile"            
          >
            <img
              src="../../public/images/SubtractBlue.svg"
              style="margin-right: 10px; width: 18px"
            />
            <p
              class="packageInfo-mobile"
              style="
                width: 300px;
                display: block;   
                margin: 0px;
                padding: 0px;    
                font-weight: 500;         
              "
            >
              Swipe left to view quantities
            </p>
          </div>
          <div
            class="scrollableArea"
            style="width: 305px; overflow-x: scroll"
            v-on:scroll="arrowCheck"
          >
            <div style="display: table; table-layout: fixed; width: 490px">
              <div class="box_headers">
                <div
                  :class="
                    index === 0
                      ? 'box_header colFixed'
                      : index === 1
                      ? 'box_header colFixed1'
                      : 'box_header'
                  "
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidthMobile[index]"
                >
                  <span>{{ header }}</span>
                </div>
              </div>
              <div
                class="box_line"
                v-for="(line, index) in order.products_detail.lines"
                v-bind:key="'line' + index"
              >
                <div class="line_item colFixed">
                  {{ line.product_no }}
                </div>
                <div
                  class="line_item colFixed1"
                  :style="getColorStyle(line.text_color_description)"
                >
                  {{ line.product_description }}
                </div>
                <div class="line_item">
                  {{ line.uom }}
                </div>
                <div class="line_item">
                  {{ line.qty_ordered }}
                </div>
                <div class="line_item">
                  {{ line.qty_shipped }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_delayed)"
                >
                  {{ line.qty_delayed }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="scrollWrapper"
            style="width: 304px; display: flex; align-items: center"
          >
            <div
              style="
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: left;
              "
            >
              <span
                class="scrollLeft"
                style="color: grey"
                v-on:click="scrollLeft"
                >＜</span
              >
            </div>
            <div
              style="
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: right;
              "
            >
              <span
                class="scrollRight"
                style="color: #33363f"
                v-on:click="scrollRight"
                >＞</span
              >
            </div>
          </div>
        </div>

        <div
          v-if="order.sales_order_type == 2"
          style="width: 360px; position: relative; margin-bottom: 10px"
          class="info_area"
        >
          <div
            style="
              width: 330px;
              height: 30px;
              position: relative;
              margin-bottom: 20px;
            "
          >
            <div
              class="orderTitle"
              style="
                width: 172px;
                height: 26px;
                margin-top: -33px;
                margin-left: -35px;
                background-color: #ed1c24;
              "
            >
              {{ order.so_warehouse }}
            </div>
          </div>
          <div
            class="orderDetail-mobile"
            style="
              padding-top: 0px;
              font-size: 11px !important;
              font-weight: normal;
            "
          >
            <div class="bold" style="width: 300px">
              OfficeMax Order Number: {{ order.so_order_no }}
            </div>
            <div style="width: 300px; display: flex">
              <div v-if="order.so_order_suffix != null">
                <span class="bold">Part: </span>{{ order.so_order_suffix }}
              </div>
              <span class="splitter" v-if="order.so_order_suffix != null"
                >|</span
              >
              <div v-if="order.packages_in_batch != null">
                <span class="bold">Boxes in this part: </span
                >{{ order.packages_in_batch }}
              </div>
            </div>
            <div style="width: 300px"><span class="bold">&nbsp;</span></div>
            <div style="width: 300px">
              <span class="bold">Carrier: </span>{{ order.so_carrier }}
            </div>
          </div>
          <div style="padding-top: 10px; margin-left: 15px">
            <div
              v-if="order.full_tracking_url != null"
              :class="getStyleObjectMobile(order.is_all_delivered)"
              style="
                text-align: center;
                margin-left: 112px !important;
                width: 196px !important;
              "
            >
              <a v-bind:href="order.full_tracking_url" target="_blank"
                >Check Courier Status</a
              >
            </div>
          </div>
          <div class="line-mobile" style="margin-top: 20px; width: 360px">
            <div class="withOfficeMax">With OfficeMax</div>
          </div>
          <div class="line-mobile" style="margin-top: 20px; position: relative">
            <div class="tooltip tooltip-mobile" style="display: none">
              <p class="tooltipContent">xxxxxxxxxxxxxxxx</p>
            </div>
            <div
              class="tooltipBoxNumber tooltipBoxNumber-mobile"
              style="display: none"
            >
              <p class="tooltipContent">
                This indicates the number of boxes at this stage.
              </p>
            </div>
            <div style="width: 360px; z-index: 2" class="imgDivs">
              <div class="imgDiv-mobile">
                <!-- <div style="width:100px;" class="withOfficeMax">With OfficeMax</div>                                                     -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[0]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status1" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[0] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[0] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile">
                <div class="line1-mobile"></div>
              </div>
              <div class="imgDiv-mobile">
                <!-- <div style="width:100px;" class="withBlank" >&nbsp;</div> -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[1]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status2" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[1] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[1] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile">
                <div class="line1-mobile"></div>
              </div>
              <div class="imgDiv-mobile">
                <!-- <div style="width:100px;" class="withBlank">&nbsp;</div>                     -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[2]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status3" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[2] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[2] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile">
                <div class="line1-mobile"></div>
              </div>
              <div
                class="withCarrier"
                style="margin-top: 15px; margin-left: -133px; z-index: 2"
              >
                With Carrier
              </div>
              <div style="position: relative">
                <div class="brownAreaOverlay-mobile" style="z-index: 1"></div>
              </div>

              <div class="imgDiv-mobile brownOverlay">
                <!-- <div style="width:90px;" class="withCarrier">With Carrier</div> -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[3]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status4" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[3] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[3] }}&nbsp;
                </div>
              </div>
              <div class="imageLine-mobile brownOverlay">
                <div class="line1-mobile"></div>
              </div>
              <div class="imgDiv-mobile brownOverlay">
                <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                <div
                  class="imageWrap imageWrap-mobile"
                  v-bind:data-desc="order.status_desc[4]"
                  style="text-align: center; width: 35px; height: 35px"
                >
                  <img class="status5" />
                </div>
                <div class="imgTitlePrefix" style="display: none">
                  <img
                    src="../../public/images/SubtractBlue.svg"
                    style="margin-left: 10px; width: 15px; float: left"
                  />
                </div>
                <div class="imgTitle-mobile">
                  {{ order.status_name[0] }}&nbsp;
                </div>
                <div
                  class="packageNo-mobile"
                  style="margin-bottom: 0px; width: 15px; height: 15px"
                >
                  <span>&nbsp;</span>
                </div>
                <div class="imgDate-mobile">
                  {{ order.status_date[4] }}&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div style="display: grid">
            <div
              class="btnOfficeMax btnOrderEmpty-mobile"
              v-bind:data-orderNo="order.so_order_no"
              v-bind:data-prefix="order.so_order_suffix"
              v-on:click="btnOrderEmptyClick"
              v-if="order.is_send_delivery_paperwork == true"
            >
              <a href="#">Send Delivery Paperwork</a>
            </div>
            <div
              class="btnOfficeMax btnOrder-mobile btnStudentDetails"
              style="opacity: 1; margin-top: 10px"
              v-if="customer.is_myschool == 'Y'"
            >
              <a href="#">View By Product</a>
            </div>
            <div
              class="btnOfficeMax btnOrder-mobile btnProductDetails"
              style="opacity: 1; margin-top: 10px"
              v-if="order.products_detail != null"
            >
              <a href="#">View By Product</a>
            </div>
            <div
              class="btnOfficeMax btnOrder-mobile btnBoxDetails"
              style="opacity: 1; margin-top: 10px"
              v-if="order.box_details != null && customer.is_myschool != 'Y'"
            >
              <a href="#">View Box Summary</a>
            </div>
          </div>
        </div>
        <div
          class="boxDetailList"
          v-if="
            order.box_details != null &&
            order.box_details.length > 0 &&
            order.sales_order_type == 2
          "
          style="
            display: none;
            width: 360px;
            padding-top: 5px;
            margin-top: 5px;
            margin-bottom: 10px;
            overflow-x: scroll;
          "
        >
          <p>Box Total : {{ order.box_details.length }}</p>
          <div
            v-for="box in order.box_details"
            v-bind:key="box.package_id"
            style="width: 305px"
          >
            <div
              style="
                width: 305px;
                /* display: flex; */
                /* align-items: center; */
                margin-top: 10px;
              "
            >
              <p
                class="boxStatus"
                style="
                  display: block;
                  float: left;
                  margin-right: 180px;
                  width: 305px;
                "
              >
                Box Status : <span>{{ box.status }}</span>
              </p>
            </div>
            <div
              class="scrollableArea"
              style="width: 305px; overflow-x: scroll"
              v-on:scroll="arrowCheck"
            >
              <div
                style="
                  display: table;
                  width: 305px;
                  table-layout: fixed;
                  width: 490px;
                "
              >
                <div class="box_headers">
                  <div
                    :class="
                      index === 0
                        ? 'box_header colFixed'
                        : index === 1
                        ? 'box_header colFixed1'
                        : 'box_header'
                    "
                    v-for="(header, index) in box.headers"
                    v-bind:key="'header' + index"
                    :style="boxHearderWidthMobile[index]"
                  >
                    <span>{{ header }}</span>
                  </div>
                </div>
                <div
                  class="box_line"
                  v-for="(line, index) in box.lines"
                  v-bind:key="'line' + index"
                >
                  <div class="line_item colFixed">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item colFixed1"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="scrollWrapper"
              style="width: 304px; display: flex; align-items: center"
            >
              <div
                style="
                  width: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: left;
                "
              >
                <span
                  class="scrollLeft"
                  style="color: grey"
                  v-on:click="scrollLeft"
                  >＜</span
                >
              </div>
              <div
                style="
                  width: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: right;
                "
              >
                <span
                  class="scrollRight"
                  style="color: #33363f"
                  v-on:click="scrollRight"
                  >＞</span
                >
              </div>
            </div>

            <div
              v-if="box.tracking_url != null"
              :class="getStyleObject(box.is_delivered)"
              style="
                width: 305px !important;
                height: 35px;
                margin-left: 0px !important;
                margin-top: 10px !important;
              "
            >
              <a v-bind:href="box.tracking_url" target="_blank"
                >Check Courier Status</a
              >
            </div>
          </div>
        </div>
        <div
          class="productDetailList"
          v-if="order.products_detail != null && order.sales_order_type == 2"
          style="
            display: none;
            width: 360px;
            padding-top: 5px;
            margin-top: 5px;
            margin-bottom: 10px;
          "
        >
          <p>{{ order.products_detail.description }}</p>
          <div
            style="width: 304px;display: flex;align-items: center;justify-content: center;margin: 10px 0px;padding: 5px 10px;"
            class="ship_info-mobile"            
          >
            <img
              src="../../public/images/SubtractBlue.svg"
              style="margin-right: 10px; width: 18px"
            />
            <p
              class="packageInfo-mobile"
              style="
                width: 300px;
                display: block;   
                margin: 0px;
                padding: 0px;  
                font-weight: 500;          
              "
            >
              Swipe left to view quantities
            </p>
          </div>
          <div
            class="scrollableArea"
            style="width: 305px; overflow-x: scroll"
            v-on:scroll="arrowCheck"
          >
            <div style="display: table; table-layout: fixed; width: 490px">
              <div class="box_headers">
                <div
                  :class="
                    index === 0
                      ? 'box_header colFixed'
                      : index === 1
                      ? 'box_header colFixed1'
                      : 'box_header'
                  "
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidthMobile[index]"
                >
                  <span>{{ header }}</span>
                </div>
              </div>
              <div
                class="box_line"
                v-for="(line, index) in order.products_detail.lines"
                v-bind:key="'line' + index"
              >
                <div class="line_item colFixed">
                  {{ line.product_no }}
                </div>
                <div
                  class="line_item colFixed1"
                  :style="getColorStyle(line.text_color_description)"
                >
                  {{ line.product_description }}
                </div>
                <div class="line_item">
                  {{ line.uom }}
                </div>
                <div class="line_item">
                  {{ line.qty_ordered }}
                </div>
                <div class="line_item">
                  {{ line.qty_shipped }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_delayed)"
                >
                  {{ line.qty_delayed }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="scrollWrapper"
            style="width: 304px; display: flex; align-items: center"
          >
            <div
              style="
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: left;
              "
            >
              <span
                class="scrollLeft"
                style="color: grey"
                v-on:click="scrollLeft"
                >＜</span
              >
            </div>
            <div
              style="
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: right;
              "
            >
              <span
                class="scrollRight"
                style="color: #33363f"
                v-on:click="scrollRight"
                >＞</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <footer
    id="footer"
    class="text-center text-lg-start bg-light text-muted"
    style="display: none"
  >
    <div class="row" style="margin-left: 0px; margin-right: 0px">
      <div style="margin-top: 30px">
        <div
          style="
            margin-left: 10px;
            width: 300px;
            text-align: left;
            justify-content: left;
          "
        >
          <div class="mb-2" style="font-weight: 600">
            Other OfficeMax Websites
          </div>
          <div><a href="https://www.officemax.co.nz">OfficeMax</a></div>
          <div><a href="https://www.myschool.co.nz">MySchool</a></div>
          <!-- <div><a href="#">Careers at OfficeMax</a></div> -->
        </div>
        <div
          style="
            margin-left: 10px;
            width: 300px;
            margin-top: 30px;
            text-align: left;
            justify-content: left;
          "
        >
          <div class="mb-2" style="font-weight: 600">Customer Support</div>
          <div><a href="/faq">Frequent Asked Questions</a></div>
          <div><a href="#">0800 426 473</a></div>
        </div>
        <div class="logo-footer-mobile" style=""></div>
      </div>
    </div>
  </footer>
  <!-- Footer -->
  <div id="overlay" style="display: none">
    <img src="../../public/images/gear-wide.svg" alt="Loading..." />
  </div>
</template>

<script>
import $ from "jquery";
import commonMixin from "./../../public/scripts/commonMixin.js";

export default {
  mixins: [commonMixin],
  // ... other component specific logic
  methods: {
    tab_header_cus_click() {
      console.log("tab_header_cus_click");
      $("#area_customer").css("display", "block");
      $("#area_delivery").css("display", "none");
      $("#tab_header_cus").css("fontWeight", "600");
      $("#tab_header_cus").css("textDecoration", "none");
      $("#tab_header_del").css("fontWeight", "300");
      $("#tab_header_del").css("textDecoration", "underline");
    },
    tab_header_del_click() {
      $("#area_customer").css("display", "none");
      $("#area_delivery").css("display", "block");
      $("#tab_header_cus").css("fontWeight", "300");
      $("#tab_header_cus").css("textDecoration", "underline");
      $("#tab_header_del").css("fontWeight", "600");
      $("#tab_header_del").css("textDecoration", "none");
    },
  },
};
</script>

<style scoped>
@import "../../public/stylesheets/style_mobile.css";
</style>
