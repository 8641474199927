<template>
  <div class="container-main">
    <div class="masthead-wrapper" style="margin-bottom: 10px;">
      <div class="logo" style="float: left;margin-left: 35px;"></div>
      <div class="logo_title" style="float: left">Order Tracking</div>
      <div class="menu" style="margin-left: 750px">
          <span class="btnMenuItem"
            ><a href="/faq" style="text-decoration: none; color: white"
              >FAQs</a
            ></span
          >
          <span class="btnMenuItem">0800 426 473</span>
        </div>
    </div>
  <!-- Overlay and spinner -->
    <div id="overlay" class="overlay">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="main-container">
      <div class="row">
        
        <div style="text-align: left;margin-bottom: 5px;">Below are your orders placed in the last 90 days. Use the
          filters
          to search for a particular order.</div>

        <div style="width: 100%;display: flex;align-items: center;justify-content: right;margin-bottom: 20px;"><a class="btn" href="https://www.officemax.co.nz" style="background-color: white; color: #657e91; border: 1px solid; border-radius: 15px;">Go to Homepage</a></div>

        <div class="row mb-3 shadow p-3 mb-5 bg-white rounded" style="width: 1830px;">
          <div class="col-md-3 col-xs-3" style="width: 30px;padding-top:26px;">
            <img src="../../public/images/filter_img.png" style="width: 20px; height: 20px;" />
          </div> 

          <div class="col-md-3 col-xs-3" style="width: 215px;">
            <label for="omxOrderNo" class="form-label"
              style="margin-bottom: 4px; display: block; text-align: left;">OfficeMax Order Number</label>
            <input id="omxOrderNo" type="text" class="form-control mb-2 border change-to-orange-box div_hover"
              placeholder="" v-model="orderNum" @focus="changeStyle" @blur="addBorder">
          </div>          
          <div class="col-md-3" style="width: 230px;">
            <label for="refNo" class="form-label" style="margin-bottom: 4px; display: block; text-align: left;">Customer Order Number</label>
            <input id="refNo" type="text" class="form-control mb-2 border change-to-orange-box div_hover"
              placeholder="" v-model="custNum" @focus="changeStyle" @blur="addBorder">
          </div>
          <div class="col-md-3" style="width: 230px;">
            <label for="shipToCode" class="form-label" style="margin-bottom: 4px; display: block; text-align: left;">Customer Number</label>
            <input id="shipToCode" type="text" class="form-control mb-2 border change-to-orange-box div_hover"
              placeholder="" v-model="shipToCode" @focus="changeStyle" @blur="addBorder">
          </div>
          <div  class="col-md-3" style="width: 270px;">
            <label class="form-label" style="margin-bottom: 4px; display: block; padding-right: 173px; width:300px">Order Date Range</label> 
            <div class="col-md-3 change-to-orange-box" style="width: 260px;height:34px; margin-top:4px">

              <!-- <label class="form-label" style="margin-bottom: 4px; display: block;padding-right: 110px;">Filter By Order Date</label> -->

              <date-range-picker ref="picker" :opens="opens" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                :minDate="minDate" :maxDate="maxDate" :singleDatePicker="singleDatePicker" :timePicker="timePicker"
                :timePicker24Hour="timePicker24Hour" :showWeekNumbers="showWeekNumbers" :showDropdowns="showDropdowns"
                :autoApply="autoApply" :dateRange=initDateRange @update="updateValues"
                @toggle="onDateRangeChange()" @start-selection="logEvent('startSelection', $event)"
                @finish-selection="logEvent('finishSelection', $event)" :linkedCalendars="linkedCalendars"
                :dateFormat="dateFormat">
              </date-range-picker>
              <img src="../../public/images/calender.png"
                style="width: 20px; height: 20px; margin-left: 16px;margin-bottom:21px" />


            </div>
          </div>

          <div class="col-md-2">
            <label for="orderStatus" class="form-label"
              style="margin-bottom: 4px; display: block; text-align: left;">Order Status</label>
            <select id="orderStatus" v-model="selectedStatus"
              class="form-select mb-2 border change-to-orange-box" @focus="changeStyle" @blur="addBorder">
              <option value="All" selected>All</option>
              <option v-for="option in orderStatusOptions" v-bind:key="option.Key" v-bind:value="option.Value">{{ option.Value }}</option>
            </select>
          </div>
          <div class="col-md-2 d-flex">
            <div class="form-check" style="padding-top:27px">
              <input class="form-check-input border border-dark" type="checkbox" id="onlyMyOrder" v-model="showOnly" v-on:change="showOnlyChanged" />
              <label class="form-check-label" for="onlyMyOrder">
                Show only my orders
              </label>
            </div>
          </div>
          <div style="padding: 20px 0;display: none;"><button class="btn_search" value="Search" id="btnSearch"
              v-on:click="btnSearchClick">
              <img src="../../public/images/search.svg" alt="Search" style="
                        margin-left: 10px;
                        margin-right: 5px;
                        width: 13px;
                        height: 13px;
                      " />Search
            </button></div>
        </div>

        <div class="table-container shadow p-3 mb-5 bg-white rounded">
          <table id="dtOrders" class="table  order-summary-table">
            <thead>
              <tr>
                <th style="text-align: left;">Order Date</th>
                <th style="text-align: left;">Invoice Date</th>
                <th style="text-align: left;">OfficeMax Order Number</th>
                <th style="text-align: left;">Customer Order Number</th>
                <th style="text-align: left;">Order Status</th>
                <th style="text-align: left;">Orderer Email</th>
                <th style="text-align: left;">Customer Number</th>
                <th style="text-align: left;">Customer Name</th>
                <th style="text-align: left;">Order Link</th>
              </tr>
            </thead>
            <tbody>
              <!-- Example data rows -->
              <tr v-for='order in orders' v-bind:key="order.id">
                <td style="text-align: left;">{{ convertToDate(order.OrderDate) }}</td>
                <td style="text-align: left;">{{ convertToDate(order.InvoiceDate) }}</td>
                <td style="text-align: left;">{{ order.OrderNumber }}</td>
                <td style="text-align: left;">{{ order.CustomerRef }}</td>
                <td style="text-align: left;">{{ order.OrderStatus }}</td>
                <td style="text-align: left;">{{ order.OrderEmail }}</td>
                <td style="text-align: left;">{{ order.ShipToCode }}</td>
                <td style="text-align: left;">{{ order.ShipToName }}</td>
                <td style="text-align: left;"><a v-if="order.OrderLink" :href="`${order.OrderLink}`" target="_blank">Track Order</a></td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <!-- Footer -->
    <footer
      id="footer"
      class="text-center text-lg-start bg-light text-muted"
      style="height:116px;margin-top:0px;"        
    >
      <div style="display: flex;justify-content: space-between;align-items: center;padding: 10px 60px;">
        <div style="display: flex;justify-content: left;align-items: center;">
          <div style="margin-right: 50px;">
            <!-- <div><a href="https://www.officemax.co.nz">OfficeMax</a></div> -->
            <!-- <div><a href="https://www.myschool.co.nz">MySchool</a></div> -->
            <div style="font-size: 16px;font-weight: 500;"><a href="/faq">Frequent Asked Questions</a></div>
          </div>
          <div style="">          
            <div style="font-size: 16px;font-weight: 500;"><a href="#">0800 426 473</a></div>
          </div>
        </div>
        <div class="row justify-content-end" style="" >
          <div style="margin-right: 50px;" class="logo-myschool"></div>
          <div class="logo-omx"></div>
        </div>
    </div>
    </footer>
    <!-- Footer -->
  </div>
</template>
<style>
@import "../../public/stylesheets/style_customer_search.css";
</style>

<script>
import customerOrdersView from "./../../public/scripts/customerOrdersView.js";

export default {
  mixins: [customerOrdersView],
};
</script>
