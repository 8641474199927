<template>
  <div class="container-main">
    <div class="masthead-wrapper">
      <div class="container masthead" style="display: flex">
        <div class="logo" style="float: left"></div>
        <div class="logo_title" style="float: left">Order Tracking</div>
        <div class="menu" style="margin-left: 750px">
          <div
            class="login"
            id="btnLogin"
            style="margin-top: 0px; width: 99px; margin-left: 30px"
            v-on:click="btnLoginClick"
            v-if="ifGuid == false"
          >
            <div>Log In</div>
          </div>
          <div
            class="login"
            id="btnLogout"
            style="margin-top: -5px; display: none"
            v-on:click="btnLogoutClick"
            v-if="ifGuid == false"
          >
            <div
              style="
                padding: 5px 10px;
                border-radius: 20px;
                border: white 1px solid;
              "
            >
              Log Out
            </div>
          </div>

          <span class="btnMenuItem"
            ><a href="/faq" style="text-decoration: none; color: white"
              >FAQs</a
            ></span
          >
          <span class="btnMenuItem">0800 426 473</span>
        </div>

        <div style="display: none" class="emailAddr">{{ session.email }}</div>
      </div>
    </div>
    <h3 v-if="orders.length > 0" style="width: 940px" class="header1">
      Track your order
      <span v-if="orders.length > 0">{{ orders[0].so_order_no }}</span>
      <button
        v-if="customer.guid_url != null && ifGuid == false"
        v-bind:link="customer.guid_url"
        v-on:click="btnShareClick"
        class="btnOfficeMax btnShare"
        style="margin-top: 12px"
      >
        <a>Share with customer</a>
      </button>
    </h3>
    <div style="display: flex">
      <div
        style="margin-left: 0px auto; margin-left: 160px; float: left"
        id="left_panel"
      >
        <div
          v-if="session.token.length > 1 && ifGuid == false && false"
          style="width: 940px"
          class="info_area"
        >
          <h4>Search by OfficeMax Order Number</h4>
          <h5>eg:12345678</h5>
          <div id="searchbar">
            <div
              id="search_input"
              style="display: flex"
              :class="{ 'active-border': inputFocused }"
            >
              <div style="float: left">
                <input
                  type="text"
                  id="terms"
                  autocomplete="off"
                  name="Ntt"
                  class="text_box"
                  value=""
                  @keyup.enter="btnSearchClick"
                  @focus="inputFocused = true"
                  @blur="inputFocused = false"
                  maxlength="8"
                  oninput="this.value = this.value.replace(/\D/g, '')"
                  v-model="orderNoBind"
                  readonly="readonly"
                />
              </div>
              <div style="float: left">
                <button
                  class="new_search"
                  value="Search"
                  id="btnSearch"
                  v-on:click="btnSearchClick"
                >
                  <img
                    src="../../public/images/search.svg"
                    alt="Search"
                    style="
                      margin-left: 10px;
                      margin-right: 5px;
                      width: 13px;
                      height: 13px;
                    "
                  />Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style="display: none; width: 940px" id="search-alert">
          <div class="img"></div>
          <p></p>
        </div>
        <p
          v-if="orders.length > 0"
          class="packageInfo"
          style="width: 900px; height: 50px; display: block"
        >
          <img
            src="../../public/images/SubtractBlue.svg"
            style="margin-right: 10px"
          />{{ orders.length }} part(s) in OfficeMax Order Number
          {{ orders[0].so_order_no }}
        </p>
        <div
          class="orderArea"
          v-for="(order, index) in orders"
          v-bind:key="order.id"
          :class="`order${index + 1}`"
          style="margin-bottom: 33px"
        >
          <div
            v-if="order.sales_order_type == 0"
            style="width: 940px; height: 370px; position: relative"
            class="info_area"
          >
            <div
              style="
                width: 940px;
                height: 30px;
                position: relative;
                margin-bottom: 20px;
              "
            >
              <div
                class="orderTitle"
                style="
                  width: 190px;
                  height: 26px;
                  margin-top: -33px;
                  margin-left: -35px;
                  background-color: #94aaba !important;
                "
              >
                {{ order.so_warehouse }}
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 11px !important;
                font-weight: normal;
                display: flex;
              "
            >
            <div style="width: 510px"></div> 
              <div
                v-if="order.full_tracking_url != null"
                :class="getStyleObject(order.is_all_delivered)"
                style="text-align: center"
              >
                <a v-bind:href="order.full_tracking_url" target="_blank"
                  >Check Courier Status</a
                >
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 11px !important;
                font-weight: normal;
                display: flex;
              "
            >
            <div class="bold" style="width: auto">
                Order Number: {{ order.so_order_no }}
              </div>
              <span class="splitter">|</span>
              <div style="width: auto">
                <span v-if="order.so_order_suffix != null" class="bold"
                  >Part: </span
                >{{ order.so_order_suffix }}
              </div>
              <span class="splitter">|</span>
              <div style="width: auto">
                <span class="bold" v-if="order.packages_in_batch != null"
                  >Boxes in this part: </span
                >{{ order.packages_in_batch }}
              </div>
              <span class="splitter">|</span>
              <div>
                <span class="bold">Carrier: </span>{{ order.so_carrier }}
              </div>
            </div>
            <div
              class="line"
              style="margin-top: 20px; width: 900px; position: relative"
            >
              <div class="withOfficeMax">With OfficeMax</div>
              <div class="withBlank">&nbsp;</div>
              <div class="withBlank" style="width: 233px !important">
                &nbsp;
              </div>
              <div class="withCarrier">With Carrier</div>
              <div class="withBlank">&nbsp;</div>
              <div class="brownAreaOverlay" style="z-index: 1"></div>
              <div class="blueAreaOverlay" style="z-index: 1"></div>
            </div>
            <div class="line" style="margin-top: 20px; position: relative">
              <div class="tooltip" style="display: none">
                <p class="tooltipContent">xxxxxxxxxxxxxxxx</p>
              </div>
              <div class="tooltipBoxNumber" style="display: none">
                <p class="tooltipContent">
                  This indicates the number of boxes at this stage.
                </p>
              </div>
              <div style="display: flex; width: 940px" class="imgDivs">
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withOfficeMax">With OfficeMax</div>                                                     -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[0]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status1" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[0] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[0] }}&nbsp;</div>
                </div>
                <div class="imageLine">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withBlank" >&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[1]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status2" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[1] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[1] }}&nbsp;</div>
                </div>
                <div class="imageLine">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div>                     -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[2]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status3" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[2] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[2] }}&nbsp;</div>
                </div>
                <div class="imageLine">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:90px;" class="withCarrier">With Carrier</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[3]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status4" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[3] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[3] }}&nbsp;</div>
                </div>
                <div class="imageLine brownOverlay">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[4]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status5" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[4] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[4] }}&nbsp;</div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  width: 940px;
                  height: 40px;
                  margin-top: 130px;
                  margin-left: -800px;
                "
              >
                <div
                  v-if="
                    order.status[2] == 1 &&
                    order.is_send_delivery_paperwork == true
                  "
                  class="btnOfficeMax btnOrderEmpty"
                  style="float: left; margin-right: 50px"
                  v-bind:data-orderNo="order.so_order_no"
                  v-bind:data-prefix="order.so_order_suffix"
                  v-on:click="btnOrderEmptyClick"
                >
                  <a href="#">Send Delivery Paperwork</a>
                </div>
                <div
                  v-if="order.products_detail != null"
                  class="btnOfficeMax btnOrder btnProductDetails"
                  style="float: left; margin-right: 50px; opacity: 1"
                >
                  <a href="#">View By Product</a>
                </div>
                <div
                  v-if="order.box_details != null && customer.is_myschool != 'Y'"
                  class="btnOfficeMax btnOrder btnBoxDetails"
                  style="float: left; opacity: 1"
                >                  
                  <a href="#">View Box Summary</a>
                </div>
                <div
                  v-if="customer.is_myschool == 'Y'"
                  class="btnOfficeMax btnOrder btnStudentDetails"
                  style="float: left; opacity: 1"
                >                  
                  <a href="#">View By Student</a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="boxDetailList"
            v-if="
              order.box_details != null &&
              order.box_details.length > 0 &&
              order.sales_order_type == 0
            "
            style="display: none; width: 940px; padding-top: 5px"
          >
            <p>Box Total : {{ order.box_details.length }}</p>
            <div
              v-for="box in order.box_details"
              v-bind:key="box.package_id"
              style="width: 870px"
            >
              <div
                style="
                  width: 870px;
                  display: flex;
                  align-items: center;
                  margin-top: 35px;
                "
              >
                <p
                  class="boxStatus"
                  style="
                    display: block;
                    float: left;
                    margin-right: 180px;
                    width: 400px;
                  "
                >
                  Box Status : <span>{{ box.status }}</span>
                </p>
                <div
                  v-if="box.tracking_url != null"
                  :class="getStyleObject(box.is_delivered)"
                >
                  <a v-bind:href="box.tracking_url" target="_blank"
                    >Check Courier Status</a
                  >
                </div>
              </div>

              <div style="display: table; width: 870px">
                <div class="box_headers">
                  <div
                    class="box_header"
                    v-for="(header, index) in box.headers"
                    v-bind:key="'header' + index"
                    :style="boxHearderWidth[index]"
                  >
                    <span>{{ header }}</span>
                  </div>
                </div>
                <div
                  class="box_line"
                  v-for="(line, index) in box.lines"
                  v-bind:key="'line' + index"
                >
                  <div class="line_item">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="productDetailList"
            v-if="order.products_detail != null && order.sales_order_type == 0"
            style="display: none; width: 940px; padding-top: 5px"
          >
            <p>{{ order.products_detail.description }}</p>
            <div style="display: table; width: 870px">
              <div class="box_headers">
                <div
                  class="box_header"
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidth[index]"
                >
                  <span>{{ header }}</span>
                </div>
              </div>
              <div
                class="box_line"
                v-for="(line, index) in order.products_detail.lines"
                v-bind:key="'line' + index"
              >
                <div class="line_item">
                  {{ line.product_no }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_description)"
                >
                  {{ line.product_description }}
                </div>
                <div class="line_item">
                  {{ line.uom }}
                </div>
                <div class="line_item">
                  {{ line.qty_ordered }}
                </div>
                <div class="line_item">
                  {{ line.qty_shipped }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_delayed)"
                >
                  {{ line.qty_delayed }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="studentDetailList"
            v-if="order.products_detail != null && order.sales_order_type == 0"
            style="display: none; width: 940px; padding-top: 5px"
          >
            <!-- <div style="background-color: white;padding-left: 30px;"> -->
              <template v-for="(student, index) in order.students_detail" v-bind:key="'student' + index">
                <div style="background-color: white;padding: 10px 20px;border-radius: 5px;margin: 30px 0px;">
                  <p style="margin-top: 0px;">
                    Student Name :&nbsp; <span class="studentName">{{ student.student_name}}</span>                  
                  </p>                  
                  <div class="box_headers" >                     
                    <div
                      class="box_header"
                      v-for="(header, index) in order.products_detail.headers"
                      v-bind:key="'header' + index"
                      :style="boxHearderWidth[index]"
                    >
                      <span>{{ header }}</span>
                    </div>                    
                  </div>                  
                  <div class="box_line" v-for="(line, index) in student.StudentProductLines" v-bind:key="'product' + index">   
                                 
                      <div class="line_item">
                        {{ line.product_no }}
                      </div>
                      <div
                        class="line_item"
                        :style="getColorStyle(line.text_color_description)"
                      >
                        {{ line.product_description }}
                      </div>
                      <div class="line_item">
                        {{ line.uom }}
                      </div>
                      <div class="line_item">
                        {{ line.qty_ordered }}
                      </div>
                      <div class="line_item">
                        {{ line.qty_shipped }}
                      </div>
                      <div
                        class="line_item"
                        :style="getColorStyle(line.text_color_delayed)"
                      >
                        {{ line.qty_delayed }}
                      </div>
                                      
                  </div>
                </div>
              </template>
            <!-- </div> -->
            <div style="display: table; width: 870px"> 
              
              
            </div>
          </div>

          <div
            v-if="order.sales_order_type == 1"
            style="width: 940px; height: 370px; position: relative"
            class="info_area"
          >
            <div
              style="
                width: 940px;
                height: 30px;
                position: relative;
                margin-bottom: 20px;
              "
            >
              <div
                class="orderTitle"
                style="
                  width: 190px;
                  height: 26px;
                  margin-top: -33px;
                  margin-left: -35px;
                "
              >
                Direct Ship
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 11px !important;
                font-weight: normal;
                display: flex;
              "
            >
              <div class="bold" style="width: 200px">
                Order Number: {{ order.so_order_no }}
              </div>
              <span class="splitter">|</span>
              <div style="width: 100px">
                <span v-if="order.so_order_suffix != null" class="bold"
                  >Part: </span
                >{{ order.so_order_suffix }}
              </div>
              <span class="splitter">|</span>
              <div style="width: 130px"><span class="bold">&nbsp;</span></div>
              <div
                v-if="order.full_tracking_url != null"
                :class="getStyleObject(order.is_all_delivered)"
                style="text-align: center"
              >
                <a v-bind:href="order.full_tracking_url" target="_blank"
                  >Check Courier Status</a
                >
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 11px !important;
                font-weight: normal;
                display: flex;
              "
            >
              <div style="width: 250px"><span class="bold">&nbsp;</span></div>
              <div style="width: 30px"></div>
              <div style="display: none">
                <span class="bold">Carrier: </span>{{ order.so_carrier }}
              </div>
            </div>
            <div
              class="line"
              style="margin-top: 20px; width: 900px; position: relative"
            >
              <div
                class="withSupplier"
                style="opacity: 1"
              >
                With Supplier
              </div>
              <div class="withBlank">&nbsp;</div>
              <div class="withBlank" style="width:248px !important;"> &nbsp; </div>
              <div class="supplierAreaOverlay" style="z-index: 1"></div>
              <div class="withCarrier">With Carrier</div> 
              <div class="withBlank">&nbsp;</div>
              <div class="brownAreaOverlay" style="z-index: 1"></div>
            </div>
            <div class="line" style="margin-top: 20px; position: relative">
              <div class="tooltip" style="display: none">
                <p class="tooltipContent">xxxxxxxxxxxxxxxx</p>
              </div>
              <div class="tooltipBoxNumber" style="display: none">
                <p class="tooltipContent">
                  This indicates the number of boxes at this stage.
                </p>
              </div>
              <div style="display: flex; width: 940px" class="imgDivs">
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withOfficeMax">With OfficeMax</div>                                                     -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[0]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status1" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[0] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[0] }}&nbsp;</div>
                </div>
                <div class="imageLine">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withBlank" >&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[1]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status2" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[1] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[1] }}&nbsp;</div>
                </div>
                <div class="imageLine">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[2]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status3" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[2] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[2] }}&nbsp;</div>
                </div>
                <div class="imageLine brownOverlay">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[3]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status4" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[3] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[3] }}&nbsp;</div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  width: 940px;
                  height: 40px;
                  margin-top: 130px;
                  margin-left: -619px;
                "
              >
                <div
                  v-if="
                    order.status[2] == 1 &&
                    order.is_send_delivery_paperwork == true
                  "
                  class="btnOfficeMax btnOrderEmpty"
                  style="float: left; margin-right: 50px"
                  v-bind:data-orderNo="order.so_order_no"
                  v-bind:data-prefix="order.so_order_suffix"
                  v-on:click="btnOrderEmptyClick"
                >
                  <a href="#">Send Delivery Paperwork</a>
                </div>
                <div
                  v-if="order.products_detail != null"
                  class="btnOfficeMax btnOrder btnProductDetails"
                  style="float: left; margin-right: 50px; opacity: 1"
                >
                  <a href="#">View By Product</a>
                </div>
                <div
                  v-if="order.box_details != null && customer.is_myschool != 'Y'"
                  class="btnOfficeMax btnOrder btnBoxDetails"
                  style="float: left; opacity: 1"
                >                  
                  <a href="#">View Box Summary</a>
                </div>
                <div
                  v-if="customer.is_myschool == 'Y'"
                  class="btnOfficeMax btnOrder btnStudentDetails"
                  style="float: left; opacity: 1"
                >                  
                  <a href="#">View By Student</a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="boxDetailList"
            v-if="
              order.box_details != null &&
              order.box_details.length > 0 &&
              order.sales_order_type == 1
            "
            style="display: none; width: 940px; padding-top: 5px"
          >
            <p>Box Total : {{ order.box_details.length }}</p>
            <div
              v-for="box in order.box_details"
              v-bind:key="box.package_id"
              style="width: 870px"
            >
              <div
                style="
                  width: 870px;
                  display: flex;
                  align-items: center;
                  margin-top: 35px;
                "
              >
                <p
                  class="boxStatus"
                  style="
                    display: block;
                    float: left;
                    margin-right: 180px;
                    width: 400px;
                  "
                >
                  Box Status : <span>{{ box.status }}</span>
                </p>
                <div
                  v-if="box.tracking_url != null"
                  :class="getStyleObject(box.is_delivered)"
                >
                  <a v-bind:href="box.tracking_url" target="_blank"
                    >Check Courier Status</a
                  >
                </div>
              </div>

              <div style="display: table; width: 870px">
                <div class="box_headers">
                  <div
                    class="box_header"
                    v-for="(header, index) in box.headers"
                    v-bind:key="'header' + index"
                    :style="boxHearderWidth[index]"
                  >
                    <span>{{ header }}</span>
                  </div>
                </div>
                <div
                  class="box_line"
                  v-for="(line, index) in box.lines"
                  v-bind:key="'line' + index"
                >
                  <div class="line_item">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="productDetailList"
            v-if="order.products_detail != null && order.sales_order_type == 1"
            style="display: none; width: 940px; padding-top: 5px"
          >
            <p>{{ order.products_detail.description }}</p>
            <div style="display: table; width: 870px">
              <div class="box_headers">
                <div
                  class="box_header"
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidth[index]"
                >
                  <span>{{ header }}</span>
                </div>
              </div>
              <div
                class="box_line"
                v-for="(line, index) in order.products_detail.lines"
                v-bind:key="'line' + index"
              >
                <div class="line_item">
                  {{ line.product_no }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_description)"
                >
                  {{ line.product_description }}
                </div>
                <div class="line_item">
                  {{ line.uom }}
                </div>
                <div class="line_item">
                  {{ line.qty_ordered }}
                </div>
                <div class="line_item">
                  {{ line.qty_shipped }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_delayed)"
                >
                  {{ line.qty_delayed }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="studentDetailList"
            v-if="order.products_detail != null && order.sales_order_type == 1"
            style="display: none; width: 940px; padding-top: 5px"
          >
            <template v-for="(line, index) in order.products_detail.lines" v-bind:key="'table' + index">
              <div  v-if="line.product_line_type == 'STUDENT'">
                <p style="padding-left: 30px;">Student Name :&nbsp; <span class="studentName">{{ line.product_description}}</span></p>
              </div>
              <div v-if="line.product_line_type == 'STUDENT'" class="box_headers" >
                <div class="box_header" style="width: 30px;border-color: white;"></div>
                <div
                  class="box_header"
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidth[index]"
                >
                  <span>{{ header }}</span>
                </div>
                <div class="box_header" style="width: 30px;border-color: white;"></div>
              </div>
              
              <div class="box_line" v-if="line.product_line_type != 'STUDENT'"> 
                  <div class="line_item" style="border-color: white;"></div>                
                  <div class="line_item">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                  <div class="line_item" style="border-color: white;"></div> 
              </div>

            </template>
            
            <div style="display: table; width: 870px">
              
              
            </div>
          </div>

          <div
            v-if="order.sales_order_type == 2"
            style="width: 940px; height: 370px; position: relative"
            class="info_area"
          >
            <div
              style="
                width: 940px;
                height: 30px;
                position: relative;
                margin-bottom: 20px;
              "
            >
              <div
                class="orderTitle"
                style="
                  width: 190px;
                  height: 26px;
                  margin-top: -33px;
                  margin-left: -35px;
                  background-color: #ed1c24;
                "
              >
                {{ order.so_warehouse }}
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 11px !important;
                font-weight: normal;
                display: flex;
              "
            >
              <div style="width: 510px"></div>
              <div
                v-if="order.full_tracking_url != null"
                :class="getStyleObject(order.is_all_delivered)"
                style="text-align: center"
              >
                <a v-bind:href="order.full_tracking_url" target="_blank"
                  >Check Courier Status</a
                >
              </div>
            </div>
            <div
              class="orderDetail"
              style="
                padding-top: 0px;
                font-size: 11px !important;
                font-weight: normal;
                display: flex;
              "
            >
            <div class="bold" style="width: 200px">
                Order Number: {{ order.so_order_no }}
              </div>
              <span class="splitter">|</span>
              <div style="width: auto">
                <span v-if="order.so_order_suffix != null" class="bold"
                  >Part: </span
                >{{ order.so_order_suffix }}
              </div>
              <span class="splitter">|</span>
              <div style="width: auto">
                <span class="bold" v-if="order.packages_in_batch != null"
                  >Boxes in this part: </span
                >{{ order.packages_in_batch }}
              </div>
              <span class="splitter">|</span>
              <div>
                <span class="bold">Carrier: </span>{{ order.so_carrier }}
              </div>
            </div>
            <div
              class="line"
              style="margin-top: 20px; width: 900px; position: relative"
            >
              <div class="withOfficeMax">With OfficeMax</div>
              <div class="withBlank">&nbsp;</div>
              <div class="withBlank" style="width: 233px !important">
                &nbsp;
              </div>
              <div class="withCarrier">With Carrier</div>
              <div class="withBlank">&nbsp;</div>
              <div class="brownAreaOverlay" style=""></div>
              <div class="blueAreaOverlay" style="z-index: 1"></div>
            </div>
            <div class="line" style="margin-top: 20px; position: relative">
              <div class="tooltip" style="display: none">
                <p class="tooltipContent">xxxxxxxxxxxxxxxx</p>
              </div>
              <div class="tooltipBoxNumber" style="display: none">
                <p class="tooltipContent">
                  This indicates the number of boxes at this stage.
                </p>
              </div>
              <div style="display: flex; width: 940px" class="imgDivs">
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withOfficeMax">With OfficeMax</div>                                                     -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[0]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status1" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[0] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[0] }}&nbsp;</div>
                </div>
                <div class="imageLine">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withBlank" >&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[1]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status2" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[1] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[1] }}&nbsp;</div>
                </div>
                <div class="imageLine">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div>                     -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[2]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status3" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[2] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[2] }}&nbsp;</div>
                </div>
                <div class="imageLine">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:90px;" class="withCarrier">With Carrier</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[3]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status4" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[3] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[3] }}&nbsp;</div>
                </div>
                <div class="imageLine brownOverlay">
                  <div class="line1"></div>
                </div>
                <div class="imgDiv brownOverlay">
                  <!-- <div style="width:100px;" class="withBlank">&nbsp;</div> -->
                  <div
                    class="imageWrap"
                    v-bind:data-desc="order.status_desc[4]"
                    style="text-align: center; width: 35px; height: 35px"
                  >
                    <img class="status5" />
                  </div>
                  <div
                    class="packageNo"
                    style="margin-bottom: 0px; width: 15px; height: 15px"
                  >
                    <span>&nbsp;</span>
                  </div>
                  <div class="imgTitle">{{ order.status_name[4] }}&nbsp;</div>
                  <div class="imgDate">{{ order.status_date[4] }}&nbsp;</div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  width: 940px;
                  height: 40px;
                  margin-top: 130px;
                  margin-left: -800px;
                "
              >
                <div
                  v-if="
                    order.status[2] == 1 &&
                    order.is_send_delivery_paperwork == true
                  "
                  class="btnOfficeMax btnOrderEmpty"
                  style="float: left; margin-right: 50px"
                  v-bind:data-orderNo="order.so_order_no"
                  v-bind:data-prefix="order.so_order_suffix"
                  v-on:click="btnOrderEmptyClick"
                >
                  <a href="#">Send Delivery Paperwork</a>
                </div>
                <div
                  v-if="order.products_detail != null"
                  class="btnOfficeMax btnOrder btnProductDetails"
                  style="float: left; margin-right: 50px; opacity: 1"
                >
                  <a href="#">View By Product</a>
                </div>
                <div
                  v-if="order.box_details != null && customer.is_myschool != 'Y'"
                  class="btnOfficeMax btnOrder btnBoxDetails"
                  style="float: left; opacity: 1"
                >                  
                  <a href="#">View Box Summary</a>
                </div>
                <div
                  v-if="customer.is_myschool == 'Y'"
                  class="btnOfficeMax btnOrder btnStudentDetails"
                  style="float: left; opacity: 1"
                >                  
                  <a href="#">View By Student</a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="boxDetailList"
            v-if="
              order.box_details != null &&
              order.box_details.length > 0 &&
              order.sales_order_type == 2
            "
            style="display: none; width: 940px; padding-top: 5px"
          >
            <p>Box Total : {{ order.box_details.length }}</p>
            <div
              v-for="box in order.box_details"
              v-bind:key="box.package_id"
              style="width: 870px"
            >
              <div
                style="
                  width: 870px;
                  display: flex;
                  align-items: center;
                  margin-top: 35px;
                "
              >
                <p
                  class="boxStatus"
                  style="
                    display: block;
                    float: left;
                    margin-right: 180px;
                    width: 400px;
                  "
                >
                  Box Status : <span>{{ box.status }}</span>
                </p>
                <div
                  v-if="box.tracking_url != null"
                  :class="getStyleObject(box.is_delivered)"
                >
                  <a v-bind:href="box.tracking_url" target="_blank"
                    >Check Courier Status</a
                  >
                </div>
              </div>

              <div style="display: table; width: 870px">
                <div class="box_headers">
                  <div
                    class="box_header"
                    v-for="(header, index) in box.headers"
                    v-bind:key="'header' + index"
                    :style="boxHearderWidth[index]"
                  >
                    <span>{{ header }}</span>
                  </div>
                </div>
                <div
                  class="box_line"
                  v-for="(line, index) in box.lines"
                  v-bind:key="'line' + index"
                >
                  <div class="line_item">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="productDetailList"
            v-if="order.products_detail != null && order.sales_order_type == 2"
            style="display: none; width: 940px; padding-top: 5px"
          >
            <p>{{ order.products_detail.description }}</p>
            <div style="display: table; width: 870px">
              <div class="box_headers">
                <div
                  class="box_header"
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidth[index]"
                >
                  <span>{{ header }}</span>
                </div>
              </div>
              <div
                class="box_line"
                v-for="(line, index) in order.products_detail.lines"
                v-bind:key="'line' + index"
              >
                <div class="line_item">
                  {{ line.product_no }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_description)"
                >
                  {{ line.product_description }}
                </div>
                <div class="line_item">
                  {{ line.uom }}
                </div>
                <div class="line_item">
                  {{ line.qty_ordered }}
                </div>
                <div class="line_item">
                  {{ line.qty_shipped }}
                </div>
                <div
                  class="line_item"
                  :style="getColorStyle(line.text_color_delayed)"
                >
                  {{ line.qty_delayed }}
                </div> 
              </div>
            </div>
          </div>
          <div
            class="studentDetailList"
            v-if="order.products_detail != null && order.sales_order_type == 2"
            style="display: none; width: 940px; padding-top: 5px"
          >
            <template v-for="(line, index) in order.products_detail.lines" v-bind:key="'table' + index">
              <div  v-if="line.product_line_type == 'STUDENT'">
                <p style="padding-left: 30px;">Student Name :&nbsp; <span class="studentName">{{ line.product_description}}</span></p>
              </div>
              <div v-if="line.product_line_type == 'STUDENT'" class="box_headers" >
                <div class="box_header" style="width: 30px;border-color: white;"></div>
                <div
                  class="box_header"
                  v-for="(header, index) in order.products_detail.headers"
                  v-bind:key="'header' + index"
                  :style="boxHearderWidth[index]"
                >
                  <span>{{ header }}</span>
                </div>
                <div class="box_header" style="width: 30px;border-color: white;"></div>
              </div>
              
              <div class="box_line" v-if="line.product_line_type != 'STUDENT'">    
                  <div class="line_item" style="border-color: white;"></div>             
                  <div class="line_item">
                    {{ line.product_no }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_description)"
                  >
                    {{ line.product_description }}
                  </div>
                  <div class="line_item">
                    {{ line.uom }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_ordered }}
                  </div>
                  <div class="line_item">
                    {{ line.qty_shipped }}
                  </div>
                  <div
                    class="line_item"
                    :style="getColorStyle(line.text_color_delayed)"
                  >
                    {{ line.qty_delayed }}
                  </div>
                  <div class="line_item" style="border-color: white;"></div> 
              </div>

            </template>
            
            <div style="display: table; width: 870px">
              
              
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          margin-left: 0px auto;
          margin-left: 50px;
          margin-top: -82px;
          float: left;
        "
        id="right_panel"
      >
        <div
          class="alertArea"
          style="width: 585px; height: 73px; margin-bottom: 33px"
          v-if="alertInfo != null"
        >
          <img src="../../public/images/Subtract.svg" />
          <p style="padding: 10px; margin-top: -30px; margin-left: 20px">
            {{ alertInfo }}
          </p>
        </div>
        <div
          style="
            width: 585px;
            height: auto;
            margin-bottom: 33px;
            padding: 18px;
            display: grid;
          "
          class="info_area"
        >
          <div class="block">
            <div class="line" v-if="customer.customer_number != null">
              <div class="bold" style="color: #657e91; width: 190px">
                Customer Number
              </div>
              <div id="custNoCustName">{{ customer.customer_number }}</div>
            </div>
            <div class="line" v-if="customer.customer_name != null">
              <div class="bold" style="color: #657e91; width: 190px">
                Customer Name
              </div>
              <div id="custNoCustName">{{ customer.customer_name }}</div>
            </div>
            <div class="line">
              <div class="bold" style="color: #657e91; width: 190px">
                OfficeMax Order Number
              </div>
              <div id="orderedBy">{{ customer.officemax_order_number }}</div>
            </div>
            <div class="line" v-if="customer.customer_order_number != null">
              <div class="bold" style="color: #657e91; width: 190px">
                Customer Order Number
              </div>
              <div id="orderedBy">{{ customer.customer_order_number }}</div>
            </div>
            <div class="line" v-if="customer.order_by != null">
              <div class="bold" style="color: #657e91; width: 190px">
                Ordered By
              </div>
              <div id="orderedBy">{{ customer.order_by }}</div>
            </div>
            <div class="line" v-if="customer.order_date != null">
              <div class="bold" style="color: #657e91; width: 190px">
                Ordered On
              </div>
              <div id="orderedOn">
                {{ formatDateToOct(customer.order_date) }}
              </div>
            </div>
            <div class="line" v-if="customer.order_phone != null">
              <div class="bold" style="color: #657e91; width: 190px">
                Orderer Phone
              </div>
              <div id="customerReference">{{ customer.order_phone }}</div>
            </div>
            <div class="line" v-if="customer.order_email != null">
              <div class="bold" style="color: #657e91; width: 190px">
                Orderer Email
              </div>
              <div id="customerReference">{{ customer.order_email }}</div>
            </div>
            <div class="line" v-if="customer.payment_method != null">
              <div class="bold" style="color: #657e91; width: 190px">
                Payment Method
              </div>
              <div id="customerReference">{{ customer.payment_method }}</div>
            </div>
          </div>
        </div>
        <div
          style="
            width: 585px;
            height: auto;
            margin-bottom: 33px;
            padding: 18px;
            display: grid;
          "
          class="info_area"
        >
          <div class="block">
            <div class="line bold">
              <div style="color: #657e91">Delivery Address:</div>
            </div>
            <div class="line address">
              <div
                id="shippingAddress"
                v-html="customer.shipping_address"
              ></div>
            </div>
          </div>
          <div class="block">
            <div class="line bold">
              <div style="color: #657e91">Special Delivery Instructions:</div>
            </div>
            <div class="line address">
              <div
                id="specialIns"
                v-html="customer.special_delivery_instruction"
              ></div>
            </div>
          </div>
        </div>
        <div
          style="width: 585px; display: flex"
          class="ship_info"
          v-if="shipInfo != null"
        >
          <!-- <img
            src="../../public/images/SubtractBlue.svg"
            style="align-self: baseline;"
          /> -->
          <p style="display: block; align-self: baseline">
            {{ shipInfo }}
          </p>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <footer
      id="footer"
      class="text-center text-lg-start bg-light text-muted"
      style="height:116px;margin-top:0px;"        
    >
      <div style="display: flex;justify-content: space-between;align-items: center;padding: 10px 60px;">
        <div style="display: flex;justify-content: left;align-items: center;">
          <div style="margin-right: 50px;">
            <!-- <div><a href="https://www.officemax.co.nz">OfficeMax</a></div> -->
            <!-- <div><a href="https://www.myschool.co.nz">MySchool</a></div> -->
            <div style="font-size: 16px;font-weight: 500;"><a href="/faq">Frequent Asked Questions</a></div>
          </div>
          <div style="">          
            <div style="font-size: 16px;font-weight: 500;"><a href="#">0800 426 473</a></div>
          </div>
        </div>
        <div class="row justify-content-end" style="" >
          <div style="margin-right: 50px;" class="logo-myschool"></div>
          <div class="logo-omx"></div>
        </div>
    </div>
    </footer>
    <!-- Footer -->
    <div id="overlay" style="display: none">
      <img src="../../public/images/gear-wide.svg" alt="Loading..." />
    </div>
  </div>
  <!--following input is for automation test tool use only. keep it false for production.-->
  <input v-if="1==0" id="sessionToken" type="text" v-model="session.token" style="display: block;"  />
</template>

<script>
import commonMixin from "./../../public/scripts/commonMixin.js";

export default {
  mixins: [commonMixin],
  // ... other component specific logic
};
</script>

<style scope>
@import "../../public/stylesheets/style_desktop.css";
</style>
