<template>
    <component :is="currentView" :guid="guid" :lastSearchKeywords="lastSearchKeywords" :ifViewUpdated="ifViewUpdated"></component>
  </template>
  
  <script>
  import MobileView from './MobileView.vue';
  import TabletView from './TabletView.vue';
  import TableLowView from './TabletLowView.vue';  
  import DesktopView from './DesktopView.vue';
  import OrdersSearchView from './OrdersSearch.vue';
  import { useRoute } from 'vue-router'
  
  export default {
    setup() {
      const route = useRoute();
      const guid = route.params.guid || '';
      console.log('home setup:guid'+guid);  
      return { guid };      
    },
    data() {
      return {
        currentView: '',
        lastSearchKeywords: '', 
        ifViewUpdated:false
      };
    },
    components: {
      MobileView,
      TabletView,
      TableLowView,
      DesktopView,
      OrdersSearchView
    },
    mounted() {
      this.updateView();
      window.addEventListener('resize', this.updateView);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateView);
    },
    methods: {
      updateView() {
        const width = window.innerWidth;
  
        if (width < 440) {
          this.currentView = 'MobileView';
        } else if (width >= 440 && width <= 950) {
          this.currentView = 'TableLowView';
        } else if (width >= 950 && width <= 1440) {
          this.currentView = 'TabletView';
        }  
        else {
          console.log('guid:'+this.guid);
          if (this.guid == null || this.guid == undefined || this.guid == ''){
            this.currentView = 'OrdersSearchView';
          }
          else{
            this.currentView = 'DesktopView';
          }
          
        }
        this.ifViewUpdated = true;
        this.lastSearchKeywords = this.getLastSearchKeywords();
      },
     
      getLastSearchKeywords() 
      {        
        return sessionStorage.getItem('lastSearchKeywords');
      },

    }
  };
  </script>
  