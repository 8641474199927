import $ from "jquery";
import DateRangePicker from 'vue3-daterange-picker'
export default {
    props: {
        guid: {
            type: String,
            required: true,
        },
        lastSearchKeywords: {
            type: String,
            required: true,
        },
        ifViewUpdated: {
            type: Boolean,
            required: true,
        },
    },
    components: { DateRangePicker },
    data() {
        return {
            orders: [],
            initDateRange: '',
            today_d: '',
            priorDate_d: '',
            orderNum: '',
            custNum: '',
            selectedStatus: '', // This will hold the selected value
            shipOrBill: 'shipTo',
            custEmail: '',
            refNo: '',
            ifOutstanding: true,
            ifCompleted: true,
            searchMessage: '',
            session: {
                email: "",
                token: "",
              },

        };
    },
    beforeMount() {
        const today = new Date()
        var priorDate = new Date(new Date().setDate(today.getDate() - 30));
        this.initDateRange = { startDate: priorDate.toString(), endDate: today };
    },
    computed: {
        email() {
            return this.$route.params.email;
        }
    },
    created: function () {
        console.log("mixin created: guid" + this.guid);

        fetch("/get-session-all")
            .then((response) => response.json())
            .then((data) => {
            console.log("got email and token from remote session.");
            if (data.email) {
                this.session.email = data.email;
                this.session.token = data.token;
                $("#btnLogin").hide();
                $("#btnLogout").show();
            } else {
                $("#btnLogin").show();
                $("#btnLogout").hide();
                sessionStorage.removeItem("lastSearchKeywords");
                //   $("#terms").off('input').on('input', function (e) {
                //         e.target.value = e.target.value.replace(/\D/g, '');
                //     });
            }
            });
        },
    mounted: function () {
        this.load();
        
        fetch("/get-session-all")
            .then((response) => response.json())
            .then((data) => {
            console.log("got email and token from remote session.");
            if (data.email) {
                this.session.email = data.email;
                this.session.token = data.token;
                $("#btnLogin").hide();
                $("#btnLogout").show();
            } else {
                $("#btnLogin").show();
                $("#btnLogout").hide();
                sessionStorage.removeItem("lastSearchKeywords");
                
            }
        }).then(()=>{
            if(window.location.href.includes("?")){
                let urlParams = new URLSearchParams(window.location.search);
                let orderId = urlParams.get('orderId');
                let orderType = urlParams.get('orderType');
                let customerNo = urlParams.get('customerNo');
                let customerType = urlParams.get('customerType');
                let customerRef = urlParams.get('customerRef');
                let orderEmail = urlParams.get('orderEmail');
                let orderSDate = urlParams.get('orderSDate');
                let orderEDate = urlParams.get('orderEDate');
                if(orderId){
                    this.orderNum = orderId;
                }
                if(orderType){
                    if(orderType=='both'){
                        this.ifOutstanding = true;
                        this.ifCompleted = true;
                    }
                    else if(orderType=='incomplete'){
                        this.ifOutstanding = true;
                        this.ifCompleted = false;
                    }
                    else if(orderType=='complete'){
                        this.ifOutstanding = false;
                        this.ifCompleted = true;
                    }
                }
                if(customerNo){
                    this.custNum = customerNo;
                }
                if(customerType){
                    this.shipOrBill = customerType;
                }
                if(customerRef){
                    this.refNo = customerRef;
                }
                if(orderEmail){
                    this.custEmail = orderEmail;
                }
                if(orderSDate)
                {
                    if(orderEDate){
                        this.today_d = new Date(orderEDate.split('-')[2],orderEDate.split('-')[1]-1,orderEDate.split('-')[0]);
                        this.priorDate = new Date(orderSDate.split('-')[2],orderSDate.split('-')[1]-1,orderSDate.split('-')[0]);
                    }
                }
                this.btnSearchClick();
            }
        }).then(()=>{
            $('.vue-daterange-picker').next('img').css('cursor','pointer');
            $('.vue-daterange-picker').next('img').off('click').on('click', ()=>{
                setTimeout(() => {
                    $('.vue-daterange-picker span').click();
                    console.log('clicked');
                }, 100);                
            });
        })
        .then(()=>{
            $('.logo-myschool').off('click').on('click', function () {
                window.location.href = 'https://www.myschool.co.nz';          
            });
            $('.logo-omx').off('click').on('click', function () {
                window.location.href = 'https://www.officemax.co.nz';          
            });   
        });

    },
    methods: {
        updateValues(val) {
            console.log('Updated Date Range:', val);
        },
        logEvent(event, data) {
            var date = new Date(data);
            console.log(event);
            if (event === 'startSelection') {
                this.priorDate_d = this.formatDateToDDMMYYYY(date);
            }
            else if (event === 'finishSelection') {
                this.today_d = this.formatDateToDDMMYYYY(date);
            }
        },
        formatDateToDDMMYYYY(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return `${day}-${month}-${year}`;
        },
        convertToDate(str) {
            if(str.split('-').length<2){
                return "";
            }
            var date = new Date(str);
            return this.formatDateToDDMMYYYY(date);
        },
        convertOrderStatus(str) {
            if (str >= 90) {
                return "Success"
            }
        },
        convertDateToDDMMMYYY(date) {
            const monthNames = ["","JAN", "FEB", "MAR", "APR",
                "MAY", "JUN", "JUL", "AUG",
                "SEP", "OCT", "NOV", "DEC"];
            const day = date.split('-')[0];
            const monthIndex = parseInt(date.split('-')[1]);
            const monthName = monthNames[monthIndex];
            const year = date.split('-')[2];
            return `${day}-${monthName}-${year}`;
        },
        changeStyle(e) {
            e.target.classList.remove('border-dark');

        },
        addBorder(event) {
            event.target.classList.add('border-dark');
        },
        load() {
            this.today_d = new Date();
            this.priorDate = new Date(new Date().setDate(this.today_d.getDate() - 30));
                     
        },
        
        btnClearFilterClick(){
            window.location.href = "/";
        },

        btnSearchClick() {
            let sdate = '';
            let edate = '';
            if(!window.location.href.includes("?")){
                sdate = $('.vue-daterange-picker span').text().split(' - ')[0];
                edate = $('.vue-daterange-picker span').text().split(' - ')[1];            
            }
            else{
                let urlParams = new URLSearchParams(window.location.search);
                sdate =  urlParams.get('orderSDate');
                edate = urlParams.get('orderEDate');
            }
            let ssdate = this.convertDateToDDMMMYYY(sdate);
            let eedate = this.convertDateToDDMMMYYY(edate); 
            this.fetchOrders(ssdate, eedate,false);
        },
        textEnter(e){
            if (e.key === 'Enter' || e.keyCode === 13) {
                e.preventDefault(); 
                console.log('Enter key pressed in input text box');
                this.btnSearchClick();
            }
        }
        ,
        filterTableRowsByColumnIndex(index, value) {
            var table = document.getElementsByClassName("order-summary-table")[0];
            var rows = table.getElementsByTagName("tr");
            for (var i = 1; i < rows.length; i++) {
                var cells = rows[i].getElementsByTagName("td");
                if (cells[index].innerHTML.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    rows[i].style.display = "";
                } else {
                    rows[i].style.display = "none";
                }
            }
        },
        btnLoginClick() {
            console.log("redirect to login");
            window.location.href = window.location.origin + "/azure-login";
        },
        btnLogoutClick() {
            fetch("/clear-session")
                .then((response) => response.json())
                .then((res) => {
                if (res.result == true) {
                    sessionStorage.removeItem("lastSearchKeywords");
                    this.orderNoBind = "";
                    window.location.href = "/";
                }
                });
        },

        fetchOrders(sdate, edate,ifFakeData) {
            this.showSpinner();
            let status = '';
            if(!this.ifCompleted){
                status = 'incomplete';
            }
            if(this.ifCompleted && !this.ifOutstanding){
                status = 'complete';
            }
            if(this.ifCompleted && this.ifOutstanding){
                status = 'both';
            }
            var baseUrl = process.env.VUE_APP_FE_URL;//'http://localhost:8080'
            let customerOrderUrl = baseUrl + "/int/v1.0/searchorders?" 
            + "orderId=" + this.orderNum 
            + "&orderType=" + status
            + "&customerNo=" + this.custNum 
            + "&customerType=" + this.shipOrBill
            + "&customerRef=" + this.refNo 
            + "&orderEmail=" + this.custEmail
            + "&orderSDate=" + sdate
            + "&orderEDate=" + edate
            + "";

            console.log(customerOrderUrl);

            if($('#dtOrders tbody tr').length>0){
                let ssdate = $('.vue-daterange-picker span').text().split(' - ')[0];
                let eedate = $('.vue-daterange-picker span').text().split(' - ')[1]; 
                window.location.href = "/?orderId=" + this.orderNum
                +"&orderType=" + status
                +"&customerNo=" + this.custNum
                +"&customerType=" + this.shipOrBill
                +"&customerRef=" + this.refNo 
                +"&orderEmail=" + this.custEmail
                +"&orderSDate=" + ssdate
                +"&orderEDate=" + eedate ;
                return;
            }

   
            //test fake data
            if(ifFakeData){
                this.orders = [
                    {
                        OrderDate:'10 Sep 2024',
                        so_invoice_date:'10 Sep 2024',
                        so_order_no:'24851761',
                        so_cust_ref:'8063861',
                        ship_to_name:'FDL NZ LTD',
                        status:90,
                        orderer_email:'ito.stewart@fdl.co.nz'
                    },
                    {
                        OrderDate:'22 Sep 2024',
                        so_invoice_date:'23 Sep 2024',
                        so_order_no:'24859999',
                        so_cust_ref:'8063861',
                        ship_to_name:'FDL NZ',
                        status:90,
                        orderer_email:'mian.yun@fdl.co.nz'
                    }
                ];
                this.hideSpinner();
                return;
            }

            if ($.fn.dataTable.isDataTable('#dtOrders')) {
                $('#dtOrders').DataTable().clear().destroy();
            }

            //fetch real data
            fetch(customerOrderUrl, {
                headers: {
                  Authorization: "Bearer " + this.session.token,
                },
            })
            .then((response) => response.json())
            .then((res) => {
                if (res.is_error == true) {
                    this.showMessage(res.message, "warn");
                    this.hasError = true;
                    this.customer = {};
                    this.orders = [];
                    return;
                  } else {
                    $("#search-alert").hide();
                  }
                console.log(res);
                this.searchMessage = res.RetrievalMessage;
                this.orders = res.CriteriaOrderDetails;
                this.hideSpinner(); 

            })
            .then(() => {
                $.fn.dataTable.ext.type.order['custom-date-dd-mm-yyyy-pre'] = function(date) {
                    if(date){
                        var parts = date.split('-');
                        return new Date(parts[2], parts[1] - 1, parts[0]).getTime();
                    }
                    else{
                        return null;
                    }
                    
                  };

                $("#dtOrders").dataTable({
                  initComplete: function () 
                  {

                    $('.dataTables_filter').html(`
                        <div class="filter-container">
                            <i class="search-icon"></i>
                            <input id="customSearchBox" type="text" placeholder="Search across results" />
                        </div>
                    `);
                    
                    $('#customSearchBox').on('input', function () {
                        $('#dtOrders').DataTable().search(this.value).draw();
                    });

                  },
                  //   dom: '<"top"f>rt<"bottom"lp><"clear">',
                  language: {
                    search: ''
                  },
                  columnDefs: [
                    { type: 'custom-date-dd-mm-yyyy', targets: [0,1] }
                    ],
                  paging: true,
                  pageLength: 10,
                  "dom": 'ftp'
                });
              }).then(()=>{
                    if(window.location.href.includes("?")){
                        let urlParams = new URLSearchParams(window.location.search);
                        let orderSDate = urlParams.get('orderSDate');
                        let orderEDate = urlParams.get('orderEDate');
                        if(orderSDate)
                        {
                            if(orderEDate){
                                $('.vue-daterange-picker span').text(orderSDate + ' - ' +orderEDate);
                            }
                        }
                    }

                    
                      
              });
        },
        showSpinner() {
            const overlay = document.getElementById('overlay');
            overlay.style.display = 'flex'; // Show the spinner
        },

        hideSpinner() {
            const overlay = document.getElementById('overlay');
            overlay.style.display = 'none'; // Hide the spinner
        },

        showMessage(message, level) {
            $("#search-alert p").html(message);
            $("#search-alert").removeClass("alert-warn");
            $("#search-alert").removeClass("alert-info");
            if (level == "warn") {
              $("#search-alert").addClass("alert-warn");
            } else {
              $("#search-alert").addClass("alert-info");
            }
      
            $("#search-alert").show();
            $("#overlay").hide();
          }
    },
    watch: {
        selectedStatus(newValue) {
            console.log('Selected Status:', newValue); // React to changes
        }
    }

};