<template>
  <div class="container-main">
    <div class="masthead-wrapper" style="margin-bottom: 10px;">
      <div class="logo" style="float: left;margin-left: 35px;"></div>
      <div class="logo_title" style="float: left">Order Tracking</div>
      <div class="menu" style="margin-left: 750px">
          <div
            class="login"
            id="btnLogin"
            style="margin-top: 0px; width: 99px; margin-left: 30px"
            v-on:click="btnLoginClick"
          >
            <div>Log In</div>
          </div>
          <div
            class="login"
            id="btnLogout"
            style="margin-top: -5px; display: none"
            v-on:click="btnLogoutClick"
          >
            <div
              style="
                padding: 5px 10px;
                border-radius: 20px;
                border: white 1px solid;
              "
            >
              Log Out
            </div>
          </div>

          <span class="btnMenuItem"
            ><a href="/faq" style="text-decoration: none; color: white"
              >FAQs</a
            ></span
          >
          <span class="btnMenuItem">0800 426 473</span>
        </div>

        <div style="display: none" class="emailAddr">{{ session.email }}</div>
    </div>
    
    <!-- Overlay and spinner -->
    <div id="overlay" class="overlay" style="display: none;">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    
    <div class="main-container" style="min-height: 580px;" v-if="session.token.length > 1">
      <div style="display: none; width: 940px" id="search-alert">
        <div class="img"></div>
        <p></p>
      </div>
      <div class="row">
        <div style="text-align: left;margin-bottom: 30px;">
          <h1>Dashboard - OfficeMax Staff Search Output</h1>
        </div>
        <div style="text-align: left;font-size: 14px;font-weight:500;margin-bottom: 20px;">
          Search for order(s) using at least 2 of the below search options
        </div>        
        <div class="row mb-3 shadow p-3 mb-5 bg-white rounded" style="width: 100%;">
          <div style="display: flex; justify-content: left; align-items: center; margin-bottom: 20px;color:#00ADEF;">
            
          </div>
          <div style="display: flex;justify-content:left;align-items: center;width: 100%;flex-wrap: nowrap;">
            <div  class="searchBoxBlock">
              <label for="omxOrderNo" class="form-label" style="margin-bottom: 4px; display: block; text-align: left;">Order Number</label>
              <a>eg:23344556</a>
              <input id="omxOrderNo" type="text" v-on:keydown="textEnter" class="form-control mb-2 border change-to-orange-box div_hover"
                placeholder="" v-model="orderNum" @focus="changeStyle" @blur="addBorder">
            </div>
            <div class="searchBoxBlock">
              <label for="customerNo" class="form-label" style="margin-bottom: 4px; display: block; text-align: left;">Customer Number</label>
              <div style="display: flex; justify-content: left; align-items: center;padding-left: 0px;margin-bottom: 10px;">
                <input id="useShipTo" type="radio" name="addressOption" value="shipTo" v-model="shipOrBill">
                <label for="useShipTo" style="margin-right: 10px;">Use Ship To</label>

                <input id="useBillTo" type="radio" name="addressOption" value="billTo" v-model="shipOrBill">
                <label for="useBillTo">Use Bill To</label>
              </div>

              <input id="customerNo" type="text" v-on:keydown="textEnter" class="form-control mb-2 border change-to-orange-box div_hover"
                placeholder="" v-model="custNum" @focus="changeStyle" @blur="addBorder">
            </div>
            <div class="searchBoxBlock">
              <label for="orderEmail" class="form-label" style="margin-bottom: 4px; display: block; text-align: left;">Order Email</label>
              <a>eg:smith@email.com</a>
              <input id="orderEmail" type="text" v-on:keydown="textEnter" class="form-control mb-2 border change-to-orange-box div_hover"
                placeholder="" v-model="custEmail" @focus="changeStyle" @blur="addBorder">
            </div>
            <div  class="searchBoxBlock">
              <label for="refNo" class="form-label" style="margin-bottom: 4px; display: block; text-align: left;">Customer Reference</label>
              <a>eg:23344556</a>
              <input id="refNo" type="text" v-on:keydown="textEnter" class="form-control mb-2 border change-to-orange-box div_hover"
                placeholder="" v-model="refNo" @focus="changeStyle" @blur="addBorder">
            </div>
            <div class="searchBoxBlock" style="width: 270px;">
              <label class="form-label" style="margin-bottom: 4px; display: block; width:300px;text-align: left;">Order Date</label> 
              <a style="padding-bottom: 9px;">Enter start and end date</a>
              <div class="col-md-3 change-to-orange-box" style="width: 217px;height:34px;margin-top: 0px;">

                <!-- <label class="form-label" style="margin-bottom: 4px; display: block;padding-right: 110px;">Filter By Order Date</label> -->

                <date-range-picker ref="picker" :opens="opens" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                  :minDate="minDate" :maxDate="maxDate" :singleDatePicker="singleDatePicker" :timePicker="timePicker"
                  :timePicker24Hour="timePicker24Hour" :showWeekNumbers="showWeekNumbers" :showDropdowns="showDropdowns"
                  :autoApply="autoApply" :dateRange=initDateRange @update="updateValues"
                  @toggle="logEvent('event: open', $event)" @start-selection="logEvent('startSelection', $event)"
                  @finish-selection="logEvent('finishSelection', $event)" :linkedCalendars="linkedCalendars"
                  :dateFormat="dateFormat">
                </date-range-picker>
                <img src="../../public/images/calender.png"
                  style="width: 20px; height: 20px; margin-left: 16px;" />


              </div>
            </div>

            <div class="searchBoxBlock">
              <div>
                <label for="orderStatus" class="form-label" style="margin-bottom: 4px; display: block; text-align: left;font-weight: 600;">Order Status</label>
              </div>
              <div style="margin-top: 13px;">
                <input type="checkbox" id="ifOutstanding" class="form-check-input border border-dark" v-model="ifOutstanding">
                <label class="form-check-label" for="ifOutstanding">Outstanding Orders</label>
              </div>
              <div style="margin-top: 12px;">
                <input type="checkbox" id="ifCompleted" class="form-check-input border border-dark" v-model="ifCompleted">
                Completed Orders
              </div>          
            </div>

            <div class="searchBoxBlock">
              <div style="margin-top: 60px;justify-content: right;">
                <button class="btn_search" value="Clear Filter" id="btnClearFilter" v-on:click="btnClearFilterClick" style="background-color: white;color: rgb(241, 101, 33);border: 1px solid;align-items: center;justify-content: center;">
                  Clear Filter
                </button>
              </div>          
            </div>

            <div class="searchBoxBlock">
              <div style="margin-top: 60px;">
                <button class="btn_search" value="Search" id="btnSearch" v-on:click="btnSearchClick" style="margin-left: 0px;">
                  <img src="../../public/images/search.svg" alt="Search" style="
                            margin-left: 10px;
                            margin-right: 5px;
                            width: 13px;
                            height: 13px;
                          " />Search
                </button>
              </div>          
            </div>
          </div>
        </div>
        <div class="alert-info" v-if="searchMessage.MessageType=='Info'">
          <div class="img" style="margin-top:1px !important;"></div>
          <p style="margin-bottom: 0px !important;">{{ searchMessage.DisplayMessage }}</p>
        </div>
        <div class="alert-warn" v-if="searchMessage.MessageType=='Error'">
          <div class="img" style="margin-top:1px !important;"></div>
          <p style="margin-bottom: 0px !important;">{{ searchMessage.DisplayMessage }}</p>
        </div>
        <div class="table-container shadow p-3 mb-5 bg-white rounded">
          <table id="dtOrders" class="table  order-summary-table">
            <thead>
              <tr>
                <th style="text-align: left;">Order Date</th>
                <th style="text-align: left;">Invoice Date</th>
                <th style="text-align: left;">Order Number</th>
                <th style="text-align: left;">Customer Reference</th>
                <th style="text-align: left;">Customer Number</th>
                <th style="text-align: left;">Customer Name</th>
                <th style="text-align: left;">Pronto Status</th>
                <th style="text-align: left;">Orderer Email</th>
                <th style="text-align: left;">Order Link</th>
              </tr>
            </thead>
            <tbody> 
              <!-- Example data rows -->
              <tr v-for='order in orders' v-bind:key="order.OrderNumber">
                <td style="text-align: left;">{{ convertToDate(order.OrderDate) }}</td>
                <td style="text-align: left;">{{ convertToDate(order.InvoiceDate) }}</td>
                <td style="text-align: left;">{{ order.OrderNumber }}</td>
                <td style="text-align: left;">{{ order.CustomerRef }}</td>
                <td style="text-align: left;">{{ order.ShipToCode }}</td>
                <td style="text-align: left;">{{ order.ShipToName }}</td>
                <td style="text-align: left;">{{ order.OrderStatus }}</td>
                <td style="text-align: left;">{{ order.OrderEmail }}</td>
                <td style="text-align: left;"><a v-if="order.OrderLink" :href="`${order.OrderLink}`" target="_blank">Track Order</a></td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <!-- Footer -->
    <footer
      id="footer"
      class="text-center text-lg-start bg-light text-muted"
      style="height:116px;margin-top:0px;"
       v-if="session.token.length > 1"
    >
      <div style="display: flex;justify-content: space-between;align-items: center;padding: 10px 60px;">
        <div style="display: flex;justify-content: left;align-items: center;">
          <div style="margin-right: 50px;">
            <!-- <div><a href="https://www.officemax.co.nz">OfficeMax</a></div> -->
            <!-- <div><a href="https://www.myschool.co.nz">MySchool</a></div> -->
            <div style="font-size: 16px;font-weight: 500;"><a href="/faq">Frequent Asked Questions</a></div>
          </div>
          <div style="">          
            <div style="font-size: 16px;font-weight: 500;"><a href="#">0800 426 473</a></div>
          </div>
        </div>
        <div class="row justify-content-end" style="" >
          <div style="margin-right: 50px;" class="logo-myschool"></div>
          <div class="logo-omx"></div>
        </div>
    </div>
    </footer>
    <!-- Footer -->

  </div>
</template>
<style>
@import "../../public/stylesheets/style_customer_search.css";
.reportrange-text{
  border: none !important;
}
.dataTables_wrapper .dataTables_filter {
    float: left;
    text-align: right;
}
.dataTables_filter input {
    width: 227px; 
    padding: 5px 30px !important; 
    border-radius: 50px!important;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    font-size: 14px;
    outline: none;
}
.dataTables_filter input:focus:focus{
  box-shadow: 0 0 0.1rem 0.1rem white, 0 0 0 0.15rem #fd7e14;
}
.filter-container {
    position: relative;
    display: inline-block;
    width: 230px;
}
.filter-container .search-icon {
    position: absolute;
    top: 26%;
    left: 15px;
    background-image: url(../../public/images/search1.svg);
    background-repeat: no-repeat;
    font-size: 14px;
    color: #aaa;
    pointer-events: none;
    width: 14px;
    height: 14px;
}

</style>

<script>
import customerOrdersView from "../../public/scripts/ordersSearch.js";

export default {
  mixins: [customerOrdersView],
};
</script>
